import { apiHeader, apiUrl } from "app/config";
import jwtAuthAxios from "./auth/jwtAuth";

const authServices = {};

authServices.getCurrentUser = async (accessToken) => {
  //   const { data } = await jwtAuthAxios.get("/auth");

  try {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("x-api-key", apiHeader);
    headers.append("Authorization", "Bearer " + accessToken);
    const response = await fetch(apiUrl + "/api/v1/user/me", {
      method: "GET",
      headers,
    });
    if (response.status === 200) {
      const data = await response.json();
      console.log("get user profile", data);
      return data.data;
    }
  } catch (e) {
    console.log(e);
  }
};

//sign in via otp ->
authServices.signIn = async (mobile, otp) => {
  try {
    const obj = {
      username: mobile,
      password: otp,
      alternate: "true",
    };
    console.log(obj);
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("x-api-key", apiHeader);
    const response = await fetch(apiUrl + "/api/auth/verify", {
      method: "POST",
      headers,
      body: JSON.stringify(obj),
    });
    if (response.status === 200) {
      const data = await response.json();

      console.log(data);
      return { data: data };
    }
  } catch (e) {
    console.log(e);
  }
};

export default authServices;
