import React from "react";
import { teamData } from "./CardData";
import TeamCard from "./TeamCard";
const TeamList = () => {
  return (
    <React.Fragment>
      {teamData.map((team, index) => (
        <TeamCard Key={team.id} item={team} />
      ))}
    </React.Fragment>
  );
};

export default TeamList;
