import { getAssetPath } from "../../utils/appHelpers";
import { ASSET_AVATARS } from "../../utils/constants/paths";

export const taskData = [
  {
    id: 1,
    name: "Case name",
    taskName: "Task Name 1",
    priority: "Medium Priority",
    AssignedTo: "User 1",
    AssignedBy: "User 2",
    taskStatus: "Open Task",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar4.jpg`, "40x40"),
  },
  {
    id: 2,
    name: "Case name",

    taskName: "Task Name 1",
    priority: "Medium Priority",
    AssignedTo: "User 1",
    AssignedBy: "User 2",
    taskStatus: "Open Task",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar7.jpg`, "40x40"),
  },
  {
    id: 3,
    name: "Case name",
    taskName: "Task Name 1",
    priority: "Medium Priority",
    AssignedTo: "User 1",
    AssignedBy: "User 2",
    taskStatus: "Open Task",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar7.jpg`, "40x40"),
  },
  {
    id: 4,
    name: "Case name",
    taskName: "Task Name 1",
    priority: "Medium Priority",
    AssignedTo: "User 1",
    AssignedBy: "User 2",
    taskStatus: "Open Task",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar6.jpg`, "40x40"),
  },
  {
    id: 5,
    name: "Case name",
    taskName: "Task Name 1",
    priority: "Medium Priority",
    AssignedTo: "User 1",
    AssignedBy: "User 2",
    taskStatus: "Open Task",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar9.jpg`, "40x40"),
  },
  {
    id: 5,
    name: "Case name",
    taskName: "Task Name 1",
    priority: "Medium Priority",
    AssignedTo: "User 1",
    AssignedBy: "User 2",
    taskStatus: "Open Task",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar4.jpg`, "40x40"),
  },
];
