import React from "react";
import { Card, Skeleton } from "@mui/material";
import { projects } from "./ProjectsList/data";
import Div from "@jumbo/shared/Div";

const Skeletons = () => {
  return (
    <Div>
      {projects.map((project, key) => (
        <>
          <Card sx={{ mb: 4, p: 2 }} key={key}>
            <Skeleton />
            <Skeleton animation={"wave"} />
            <Skeleton animation={false} />
          </Card>
        </>
      ))}
    </Div>
  );
};

export default Skeletons;
