import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Div from "@jumbo/shared/Div";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
function EachClient({ count, clientNo, setClientNo, name }) {
  const [documentUploaded, setDocumentUploaded] = useState();

  const [num, setNum] = React.useState();
  const [pin, setPin] = React.useState();

  const [fileUploaded, setFileUploaded] = useState(false);
  const hiddenFileInput = React.useRef(null);

  const handleInputChange = (event) => {
    const fileUploaded = event.target.files[0];
    console.log(fileUploaded);

    setDocumentUploaded(URL.createObjectURL(event.target.files[0]));
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  console.log(documentUploaded);

  const removeDocument = () => {
    setDocumentUploaded(null);
    setFileUploaded(false);
  };
  const [expanded, setExpanded] = React.useState(count === 0 ? true : false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleNumberChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setNum(e.target.value);
    }
  };

  const handlePinChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setPin(e.target.value);
    }
  };
  return (
    <Accordion
      elevation={0}
      sx={{
        marginBottom: 1,
        width: "100%",
        border: "none",
      }}
      expanded={expanded === true}
      onChange={handleChange(true)}
    >
      <AccordionSummary
        sx={{ height: "50px" }}
        expandIcon={<ExpandMoreIcon />}
        id="panel1bh-header"
      >
        <Typography
          variant="h6"
          sx={{
            width: "90%",
            flexShrink: 0,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {name} {count + 1}
          {count !== 0 && (
            <p onClick={() => setClientNo(clientNo - 1)}>
              <DeleteOutlineIcon sx={{ fontSize: "24px" }} />
            </p>
          )}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          {/* <CaseContainer> */}
          <Div
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            {/* first name */}
            <TextField
              sx={{ width: "100%" }}
              id="outlined-basic"
              label="Full Name*"
              variant="outlined"
            />
            <TextField
              sx={{ mb: 0, mt: 2, width: "100%" }}
              // type="number"
              id="outlined-basic"
              label="Enter Mobile Number*"
              variant="outlined"
              onChange={(e) => handleNumberChange(e)}
              value={num}
              inputProps={{ maxLength: 10, minLength: 10 }}
            />
            {/* email */}
            <TextField
              sx={{ mb: 0, mt: 2, width: "100%" }}
              id="outlined-basic"
              label="Email"
              variant="outlined"
            />
            {/* add addresses */}
            <Typography variant="h6" sx={{ mb: 0, mt: 2 }}>
              Add Addresses
            </Typography>
            {/* house flat */}{" "}
            <TextField
              sx={{ mb: 0, mt: 2, width: "100%" }}
              id="outlined-basic"
              label="House/Flat/Office"
              variant="outlined"
            />
            {/* pincode */}
            <TextField
              sx={{ mb: 0, mt: 2, width: "100%" }}
              // type="number"
              id="outlined-basic"
              label="Pin Code"
              variant="outlined"
              onChange={(e) => handlePinChange(e)}
              value={pin}
              inputProps={{ maxLength: 6, minLength: 6 }}
            />
            {/* city */}
            <TextField
              sx={{ mb: 0, mt: 2, width: "100%" }}
              id="outlined-basic"
              label="City"
              variant="outlined"
            />
            {/* state*/}
            <TextField
              sx={{ mb: 0, mt: 2, width: "100%" }}
              id="outlined-basic"
              label="State"
              variant="outlined"
            />
            {/* Add Client Photo */}
            <Typography variant="h6" sx={{ mb: 0, mt: 2 }}>
              Add Client Photo
            </Typography>
            {/* upload */}
            {/* <Div sx={{ mt: "20px", width: "100%" }}>
              
              </Div> */}
            <Box
              sx={{
                mb: 0,
                mt: 2,
                width: "100%",
                height: "50.71px",

                border: "1px solid #cdced1",
                borderRadius: "3px",

                display: "flex",
                alignItems: "center",
              }}
            >
              <Div sx={{ display: "flex", alignItems: "center" }}>
                <AddPhotoAlternateIcon
                  sx={{ fontSize: "40px" }}
                  onClick={handleClick}
                />
                <Typography sx={{ fontSize: "14px", color: "#929faf" }}>
                  Attach Photo
                </Typography>
              </Div>
            </Box>
            <input
              style={{ mb: 0, mt: 2, width: "30vw" }}
              accept="image/*"
              multiple
              type="file"
              ref={hiddenFileInput}
              onChange={handleInputChange}
              hidden
            />
            <Typography
              sx={{ color: "#929faf", fontSize: "12px", mb: 0, mt: "2px" }}
            >
              File Should be jpg, jpeg, png, Pdf
            </Typography>
            {/* uploaded image */}
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                overflowX: "scroll",
                width: "100%",
              }}
            >
              {documentUploaded && (
                <Div
                  sx={{
                    position: "relative",
                    // backgroundColor: "green",
                    mr: 2,
                  }}
                >
                  <img
                    style={{
                      width: { md: "5vw" },
                      height: "10vh",
                      borderRadius: "50%",
                      objectFit: "contain",
                      marginRight: "10px",
                    }}
                    src={documentUploaded}
                    alt=""
                  />
                  <CloseIcon
                    onClick={removeDocument}
                    sx={{ position: "absolute", right: 2 }}
                  />
                </Div>
              )}
            </Div>
            {documentUploaded && (
              <Typography sx={{ color: "green", fontSize: "16px" }}>
                Completed!
              </Typography>
            )}
            <Div
              sx={{
                mt: 5,
                display: "flex",
                justifyContent: "flex-end",
                // alignItems: "center",
              }}
            >
              {count !== 0 && (
                <Div
                  onClick={() => setClientNo(clientNo - 1)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                    Delete Client
                  </Typography>
                  <DeleteOutlineIcon sx={{ fontSize: "26px" }} />
                </Div>
              )}
            </Div>
          </Div>

          {/* </CaseContainer> */}
        </div>
      </AccordionDetails>
    </Accordion>
    //   </Div>
    // </div>
  );
}

export default EachClient;
