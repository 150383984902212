import * as React from "react";
import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import { useEffect } from "react";
import { Breadcrumbs, Button, CardContent, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import TaskDetails from "./TaskDetails";
import { useState } from "react";
import { Card } from "@mui/material";
import TaskListLayout from "./TaskListLayout";
import DocumentListLayout from "../Documents/DocumentListLayout";
import { useFormik } from "formik";
const AddCase = () => {
  const location = useLocation();
  const [allTasks, setAllTasks] = useState(false);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setAllTasks(false);
  }, []);

  const [value, setValue] = React.useState(0);
  const [buttonValue, setButtonValue] = React.useState(0);

  console.log("button is ", buttonValue);
  console.log("value rn is", value);

  const handleBackButton = () => {
    if (value !== 0) {
      setButtonValue((prevButtonValue) => prevButtonValue - 1);
      setValue((prevButtonValue) => prevButtonValue - 1);
    }
  };

  //modals
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  console.log("open value is ", open);

  //validation
  const [error, setError] = React.useState([]);

  console.log(error);

  if (!allTasks) {
    return (
      <Div sx={{ height: "100%", width: "100%" }}>
        <Div sx={{ mb: 2, mt: -3 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} fontSize="inherit" />
              Home
            </Typography>

            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <ListAltOutlinedIcon sx={{ fontSize: 20 }} />
              &nbsp; To do Task
            </Typography>
          </Breadcrumbs>
        </Div>

        <Card
          sx={{
            backgroundColor: "white",
            height: "100%",
          }}
        >
          <CardContent>
            <Div
              sx={{
                height: "100%",
                position: "relative",
                paddingTop: "10px",
                //  paddingBottom: "0px",
              }}
            >
              <Div
                sx={{
                  width: "100%",
                  position: "relative",

                  height: "fit-content",
                  // height: "10%",
                  mt: -2,
                  mb: 8,
                }}
              >
                <Div
                  sx={{
                    position: "absolute",

                    borderBottom: "1px solid #D3D3D3 ",
                    width: "100%",
                    pt: 0,
                    pb: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Breadcrumbs aria-label="breadcrumb">
                    {/* <GavelOutlined sx={{ fontSize: 20 }} /> */}
                    <Typography
                      variant="h5"
                      color="text.primary"
                      // sx={{ marginLeft: "10px" }}
                    >
                      To do Task
                    </Typography>
                  </Breadcrumbs>

                  <Typography
                    variant="h6"
                    color="text.primary"
                    sx={{ cursor: "pointer" }}
                    //   onClick={resetValue}
                    // sx={{ marginLeft: "10px" }}
                  >
                    {/* Reset */}
                  </Typography>
                </Div>
              </Div>
              {/* breadcrum */}
              <Div
                sx={{
                  pl: { sm: "0", lg: "11%" },
                  pr: { sm: "0", lg: "11%" },
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",

                  alignItems: "center",
                }}
              >
                {/* Add Task Area */}
                <TaskDetails
                  setAllTasks={setAllTasks}
                  value={value}
                  setValue={setValue}
                  setButtonValue={setButtonValue}
                  allTasks={allTasks}
                />
              </Div>
            </Div>
          </CardContent>
        </Card>
      </Div>
    );
  } else {
    return <TaskListLayout setAllTasks={setAllTasks} />;
  }
};

export default AddCase;
