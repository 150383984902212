import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { PaymentDetailsValidations } from "app/utils/validators";

const PaymentDetails = ({ handleBackButton }) => {
  const navigate = useNavigate();
  const LawyerType = ["Type1", "Type2", "Type3", "Type4"];
  const PracticeCourt = ["Court1", "Court2", "Court3", "Court4"];
  const [lawyer, setLawyer] = React.useState([]);
  const [court, setCourt] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [caseTitleError, setCaseTitleError] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      lawyer: lawyer,
      court: court,
    },
    validationSchema: PaymentDetailsValidations,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values, "FORMIK");
      navigate("/lawyers/teams");
    },
  });
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        paddingBottom: "30px",
      }}
    >
      {/* <CaseContainer> */}
      <Div
        sx={{
          marginLeft: { xs: "0%", md: "18%" },
          marginRight: { xs: "0%", md: "18%" },

          height: "100%",
        }}
      >
        <Autocomplete
          value={formik.values.lawyer}
          onChange={(event, value) => setLawyer(value)}
          style={{ width: "100%" }}
          multiple
          options={LawyerType}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          sx={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              sx={{ mb: 2 }}
              {...params}
              label="Lawyer Type*"
              color="secondary"
              placeholder="Lawyer Type*"
              error={formik.errors.lawyer}
              name="lawyer"
              helperText={formik.errors.lawyer}
            />
          )}
        />

        <Autocomplete
          value={formik.values.court}
          onChange={(event, value) => setCourt(value)}
          style={{ width: "100%" }}
          multiple
          options={PracticeCourt}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          sx={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              sx={{ mb: 0, mb: 2 }}
              {...params}
              label="Select Court Type*"
              color="secondary"
              placeholder="Select Court Type"
              error={formik.errors.court}
              name="court"
              helperText={formik.errors.court}
            />
          )}
        />
        <Div sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormControlLabel
            control={<Checkbox color="secondary" />}
            label="Address same as personal"
          />
          <Button
            style={{ fontWeight: "700" }}
            startIcon={<AddIcon />}
            color="secondary"
          >
            Add New
          </Button>
        </Div>
        <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          <LoadingButton
            onClick={handleBackButton}
            // disabled={values !== 0 ? false : true}
            variant="contained"
            sx={{ width: 100, padding: 1 }}
          >
            Back
          </LoadingButton>
          <LoadingButton
            onClick={formik.handleSubmit}
            color="secondary"
            style={{
              backgroundColor: "black",
            }}
            sx={{ width: 100, padding: 1 }}
            variant="contained"
          >
            Continue
          </LoadingButton>
        </Div>
      </Div>
    </div>
  );
};

export default PaymentDetails;
