import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import ProjectsList from "./ProjectsList";
import Div from "@jumbo/shared/Div";
import { Breadcrumbs, Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Skeletons from "./Skeletons";
import BasicDatePicker from "./BasicDatePicker";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import HomeIcon from "@mui/icons-material/Home";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CardFooter from "../components/CardFooter";

const Schedule = () => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = React.useState("today");

  const handleChange = (event, newDate) => {
    setDate(newDate);
  };

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <Div sx={{ height: "100%", width: "100%" }}>
      <Div sx={{ mb: 2, mt: -3 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} />
            Home
          </Typography>

          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <CalendarMonthOutlinedIcon sx={{ fontSize: 20 }} />
            &nbsp; Schedules
          </Typography>
        </Breadcrumbs>
      </Div>

      <Card>
        <CardContent>
          <Div
            sx={{
              width: "100%",
              position: "relative",
              height: "fit-content",
              mt: -2,
              mb: 8,
            }}
          >
            <Div
              sx={{
                position: "absolute",
                mt: 1,
                mb: 1,
                borderBottom: "1px solid #D3D3D3 ",
                width: "100%",
                pt: 0,
                pb: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h5" color="text.primary">
                Schedules
              </Typography>

              <Div>
                <Button
                  style={{ fontWeight: "600" }}
                  startIcon={<FilterAltOutlinedIcon />}
                  color="secondary"
                >
                  Filter By
                </Button>
              </Div>
            </Div>
          </Div>

          <Div sx={{ mb: 1, borderBottom: "1px solid #D3D3D3 " }}>
            <Div
              sx={{
                mb: 2,
                display: "flex ",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <ToggleButtonGroup
                color="secondary"
                value={date}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
                size="small"
              >
                <ToggleButton value="back">Back</ToggleButton>
                <ToggleButton value="today">Today</ToggleButton>
                <ToggleButton value="next">Next</ToggleButton>
              </ToggleButtonGroup>
              <Div sx={{ mt: 1, display: { xs: "none", lg: "block" } }}>
                <BasicDatePicker />
              </Div>
              <Button
                size="small"
                variant="contained"
                color="primary"
                disableElevation
                disableTouchRipple
              >
                Agenda
              </Button>
            </Div>
            <Div
              sx={{
                display: { xs: "block", lg: "none" },
                mb: 2,
              }}
            >
              <BasicDatePicker />
            </Div>
          </Div>
          {loading && <Skeletons />}
          {!loading && <ProjectsList />}
        </CardContent>
        <Div
          sx={{
            // position: "absolute",
            // bottom: 0,
            //right: { sm: 0, lg: 25 },
            display: "flex",
            justifyContent: "flex-end",
            mr: 3,
            mb: 3,
          }}
        >
          <CardFooter />
        </Div>
      </Card>
    </Div>
  );
};

export default Schedule;
