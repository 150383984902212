import React, { useEffect, useState } from "react";
import { Button, CardContent, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import * as yup from "yup";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import Container from "@mui/material/Container";
import SwipeableTextMobileStepper from "../../components/SwipeableTextMobileStepper";
import Login from "../login";
import LoginFooter from "app/pages/components/LoginFooter";
import { generateOtp, getUserProfile, validateOtp } from "app/Apis/Login";
import { useDispatch } from "react-redux";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  defaultAccessToken,
  DefaultEmailVerified,
  DefaultName,
  DefaultPhoneVerified,
  DefaultProfileImage,
  defaultProfileStatus,
  defaultRole,
  DefaultUserEmail,
  DefaultUserMobile,
  DefaultUserName,
} from "app/redux/actions/OnboardingInputs";
import { useSelector } from "react-redux";
import Animation from "app/pages/components/Animations/Animation";
const validationSchema = yup.object({
  otp: yup
    .string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(6, "Must be exactly 6 digits")
    .max(6, "Must be exactly 6 digits"),
});

const Otp = ({ disableSmLogin, phoneNumber }) => {
  const [isLoading, setIsLoading] = useState(false);
  // const { setAuthToken } = useJumboAuth();

  const dispatch = useDispatch();
  const [editNumber, setEditNumber] = useState("");
  const [edit, setEdit] = useState(false);
  const [otp, setOtp] = useState();
  const [seconds, setSeconds] = useState(30);
  const [searchParams, setSearchParams] = useSearchParams();

  const removeQueryParams = () => {
    const param = searchParams.get("phoneNumber");

    if (param) {
      // 👇️ delete each query param
      searchParams.delete("phoneNumber");

      // 👇️ update state after
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  //access Token
  const accessToken = useSelector(
    (state) => state?.onboardingInputs?.accessToken
  );

  const profileStatus = useSelector(
    (state) => state?.onboardingInputs?.profileStatus
  );

  const [error, setError] = useState();

  const navigate = useNavigate();

  let role = "";
  const OtpReceived = async (otp) => {
    setIsLoading(true);
    const response = await validateOtp(phoneNumber, otp);
    response?.data.data.roles.map((e) => (role = e));
    if (response?.data.success === true) {
      setError("");
      dispatch(defaultRole(role));
      dispatch(defaultAccessToken(response.data.data.accessToken));

      if (response.data.data.accessToken) {
        console.log("received");
        const userData = await getUserProfile(response.data.data.accessToken);
        console.log(userData.data.data.userImageProfileLink);
        dispatch(defaultProfileStatus(userData.data.data.profileStatus));
        dispatch(DefaultUserEmail(userData.data.data.email));
        dispatch(DefaultName(userData.data.data.fullname));
        dispatch(DefaultUserName(userData.data.data.username));
        dispatch(DefaultUserMobile(userData.data.data.phone));
        dispatch(DefaultProfileImage(userData.data.data.userProfileImageLink));
        dispatch(DefaultEmailVerified(userData.data.data.isEmailVerified));
        dispatch(DefaultPhoneVerified(userData.data.data.phoneVerified));
        navigate("/onboarding");
      }
    } else {
      setError("Please enter correct OTP!");
    }
    setIsLoading(false);
  };

  const getOtp = async () => {
    console.log("clicked");
    const response = await generateOtp(phoneNumber);
    console.log(response);
  };

  const resendOTP = async () => {
    console.log("resend clicked");
    await getOtp();
    setSeconds(30);
  };

  const handleChange = (event) => {
    setOtp(event.target.value);
  };
  return (
    <>
      {isLoading ? <Animation /> : null}
      {edit ? (
        <Login editNumber={editNumber} />
      ) : (
        <Div
          sx={{
            width: "100%",
            height: "100vh",
            margin: "auto",
            backgroundColor: "#FFFFFF",
          }}
        >
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              flexDirection: { xs: "column", md: "row" },
              height: "100%",
              borderRadius: "0",
            }}
          >
            <Div
              sx={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Container maxWidth="sm">
                <CardContent sx={{ flex: 1, p: 2 }}>
                  <Div
                    sx={{
                      display: "flex",
                      mb: 1,
                    }}
                  >
                    <img
                      src={`${ASSET_IMAGES}/lawinzo/logo-white.png`}
                      style={{
                        objectFit: "contain",
                        width: "20%",
                      }}
                      alt="Jumbo React"
                    />
                  </Div>
                  <Typography
                    variant={"h2"}
                    sx={{
                      color: "#151513",
                      fontSize: "1rem",
                      fontWeight: "700",
                      mb: 1,
                    }}
                  >
                    Access the Lawinzo Pannel
                  </Typography>
                  <Div
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      variant={"h2"}
                      sx={{
                        color: "#151513",
                        fontSize: "0.9rem",
                        fontWeight: "400",
                        mb: 2,
                      }}
                    >
                      OTP send on +91 {phoneNumber}
                    </Typography>
                    <Typography
                      variant={"h2"}
                      onClick={() => {
                        removeQueryParams();
                        setEditNumber(phoneNumber);
                        setEdit(true);
                      }}
                      sx={{
                        color: "#EABF4E",
                        fontSize: "0.9rem",
                        fontWeight: "700",
                        mb: 2,
                        cursor: "pointer",
                      }}
                    >
                      Edit ?
                    </Typography>
                  </Div>
                  <Formik
                    validateOnChange={true}
                    initialValues={{
                      otp: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(data, { setSubmitting }) => {
                      setSubmitting(false);
                      OtpReceived(data.otp);
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form
                        style={{ textAlign: "left" }}
                        noValidate
                        autoComplete="off"
                      >
                        <Div sx={{ mb: 2 }}>
                          <JumboTextField
                            fullWidth
                            name="otp"
                            label="OTP"
                            type="text"
                            color="secondary"
                            inputProps={{ maxLength: 6 }}
                          />
                          {error && (
                            <Div sx={{ display: "flex", alignItems: "center" }}>
                              <ErrorOutlineIcon
                                color="error"
                                fontSize="small"
                              />
                              <Typography
                                sx={{
                                  color: "red",
                                  fontSize: "12px",
                                  ml: 1,
                                }}
                              >
                                {error}
                              </Typography>
                            </Div>
                          )}
                        </Div>
                        <LoadingButton
                          fullWidth
                          type="submit"
                          variant="contained"
                          size="large"
                          sx={{ mb: 3 }}
                          loading={isSubmitting}
                        >
                          VERIFY OTP
                        </LoadingButton>
                        <Div
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 4,
                          }}
                        >
                          <Typography
                            variant={"body1"}
                            sx={{
                              fontSize: "0.8rem",
                              fontWeight: "500",
                            }}
                          >
                            Time Remaining: {seconds} seconds
                          </Typography>
                          <Button
                            disabled={seconds > 0}
                            variant="text"
                            onClick={resendOTP}
                          >
                            Resend OTP
                          </Button>
                        </Div>
                        {!disableSmLogin && (
                          <React.Fragment>
                            <LoginFooter />
                          </React.Fragment>
                        )}
                      </Form>
                    )}
                  </Formik>
                </CardContent>
              </Container>
            </Div>
            <Div
              sx={{
                flex: "1 1",
                height: "100%",
                backgroundColor: "#F5F5F5",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Div
                  sx={{
                    display: "flex",
                    minWidth: 0,
                    flex: 1,
                    flexDirection: "column",
                    color: "common.black",
                    zIndex: 1,
                    height: "100%",
                  }}
                >
                  <Div sx={{ mb: 2 }}></Div>
                  <Grid item xs={12} md={6}>
                    <SwipeableTextMobileStepper />
                  </Grid>
                </Div>
              </CardContent>
            </Div>
          </Div>
        </Div>
      )}
    </>
  );
};

export default Otp;
