import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import { Button, Typography } from "@mui/material";
import React from "react";
import NoCaseImage from "../../ImageAssets/NoCaseImage.png";
import { Link } from "react-router-dom";
import { teamData } from "./CardData";
import LawyerListLayout from "./LawyerListLayout";

function AddLawyer() {
  return (
    <>
      {teamData.length > 0 ? (
        <LawyerListLayout />
      ) : (
        <Div
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Div
            sx={{ width: { xs: "60%", sm: "30%", md: "150px" }, height: "30%" }}
          >
            <img
              src={NoCaseImage}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              alt=""
            />
          </Div>
          <Typography
            sx={{
              fontSize: { xs: "18px", md: "24px" },
              textAlign: "center",
              mt: 2,
            }}
          >
            You haven't added any Lawyers yet!
          </Typography>

          <Div
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              mt: 4,
            }}
          >
            <Link style={{ textDecoration: "none" }} to="/lawyers/addlawyer">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "secondary.main",
                  mb: 2,
                  width: "150px",
                }}
              >
                Add Lawyer
              </Button>
            </Link>
            {/* </Link> */}
            <Typography sx={{ mb: 2 }}>Or</Typography>
            <Typography
              sx={{
                fontSize: { xs: "18px", md: "20px" },
                color: "secondary.main",
                fontWeight: 500,
              }}
            >
              Import Data
            </Typography>
          </Div>
        </Div>
      )}
    </>
  );
}

export default AddLawyer;
