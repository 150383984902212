import React from "react";
import Div from "@jumbo/shared/Div";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PersonalDetails from "../Teams/PersonalDetails";
import OfficialDetails from "../Teams/OfficialDetails";
import PaymentDetails from "../Teams/PaymentDetails";
import { LoadingButton } from "@mui/lab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Div
          sx={{
            pt: 3,
          }}
        >
          {children}
        </Div>
      )}
    </div>
  );
}

const UpperSelectBar = () => {
  const [values, setValues] = React.useState(0);
  const [buttonValue, setButtonValue] = React.useState(0);

  const handleNextButton = () => {
    if (values !== 2) {
      setButtonValue((prevButtonValue) => prevButtonValue + 1);
      setValues((prevButtonValue) => prevButtonValue + 1);
    }
  };

  const handleBackButton = () => {
    if (values !== 0) {
      setButtonValue((prevButtonValue) => prevButtonValue - 1);
      setValues((prevButtonValue) => prevButtonValue - 1);
    }
  };
  return (
    <Div
      sx={{
        width: "100%",
      }}
    >
      <Div
        sx={{
          display: "flex",
          justifyContent: "center",
          // borderBottom: 1,
          // borderColor: "divider",

          //maxWidth: { xs: 300, sm: 600, md: 700, lg: 800 },
          //   overflowX: "scroll",
        }}
      >
        <Tabs
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          value={values}
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab
            sx={{
              fontSize: { xs: "14px", md: "14px" },
              fontWeight: "800",
            }}
            label="Personal Details"
          />
          <Tab
            sx={{
              fontSize: { xs: "14px", md: "14px" },
              fontWeight: "800",
            }}
            label="Official Details"
          />
          <Tab
            sx={{
              fontSize: { xs: "14px", md: "14px" },
              fontWeight: "800",
            }}
            label="Payment Details"
          />
        </Tabs>
      </Div>
      <TabPanel value={values} index={0}>
        <PersonalDetails
          handleBackButton={handleBackButton}
          handleNextButton={handleNextButton}
        />
      </TabPanel>
      <TabPanel value={values} index={1}>
        <OfficialDetails
          handleBackButton={handleBackButton}
          handleNextButton={handleNextButton}
        />
      </TabPanel>
      <TabPanel value={values} index={2}>
        <PaymentDetails
          handleBackButton={handleBackButton}
          handleNextButton={handleNextButton}
        />
      </TabPanel>
    </Div>
  );
};

export default UpperSelectBar;
