import * as React from "react";
import Div from "@jumbo/shared/Div";
import { useEffect } from "react";
import { Breadcrumbs, Button, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import { useState } from "react";
import TaskSkeletons from "./TaskSkeletons";
import TaskList from "./TaskList";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddIcon from "@mui/icons-material/Add";

import { Card, Divider } from "@mui/material";
import CardFooter from "../components/CardFooter";

const TaskListLayout = ({setAllTasks}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [reset, setReset] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  const resetValue = () => {
    setReset(true);
  };

  const [value, setValue] = React.useState(0);
  const [buttonValue, setButtonValue] = React.useState(0);

  console.log("button is ", buttonValue);
  console.log("value rn is", value);

  //modals
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  console.log("open value is ", open);

  //validation
  const [error, setError] = React.useState([]);

  return (
    <React.Fragment>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} />
          Home
        </Typography>

        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <ListAltOutlinedIcon sx={{ fontSize: 20 }} />
          &nbsp; To do Task
        </Typography>
      </Breadcrumbs>
      <Card
        sx={{
          p: 3,
          position: "relative",
        }}
      >
        <Div
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <Typography variant={"h5"}>All Tasks</Typography>
              <Button
                style={{ fontWeight: "700" }}
                startIcon={<AddIcon />}
                color="secondary"
                onClick={() => setAllTasks(true)}
              >
                Add Task
              </Button>
            </Div>
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Div sx={{ width: "100%" }}>
                <Button
                  style={{ fontWeight: "700" }}
                  startIcon={<FilterAltOutlinedIcon />}
                  color="secondary"
                >
                  Filter By
                </Button>
              </Div>
            </Div>
          </Div>
          <Divider sx={{ mt: 2 }} />
          <Div sx={{ mb: 10 }}>
            {loading && <TaskSkeletons />}
            {!loading && <TaskList />}
          </Div>
          <Div
            sx={{
              position: "absolute",
              bottom: 20,
              right: { sm: 0, lg: 25 },
            }}
          >
            <CardFooter />
          </Div>
        </Div>
      </Card>
    </React.Fragment>
  );
};

export default TaskListLayout;
