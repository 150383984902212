import React from "react";
import ClientCard from "./ClientCard";
import { ClientData } from "./ClientData";
const ClientList = () => {
  return (
    <React.Fragment>
      {ClientData.map((team, index) => (
        <ClientCard Key={team.id} item={team} />
      ))}
    </React.Fragment>
  );
};

export default ClientList;
