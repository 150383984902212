import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const myAge = [];
for (let i = 1; i <= 100; i++) {
  myAge.push(i);
}

export default function AgeSelectorField() {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <FormControl fullWidth sx={{ mb: 2 }} color="secondary">
      <InputLabel>Age *</InputLabel>
      <Select required value={age} label="Age " onChange={handleChange}>
        {myAge.map((age) => (
          <MenuItem key={age} value={age}>
            {age}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
