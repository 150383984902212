import {
  BARCOUNCILNO,
  PRACTICESTATES,
  PRACTICECOURTS,
  LAWYEREDUCATION,
  LAWYERSPECIALISATION,
  LAWYERLANGUAGE,
  LAWYERNAME,
  LAWYEREMAIL,
  LAWYERMOBILE,
} from "../../utils/constants/LawinzoUpdatedInputs";

const INIT_STATE = {
  name: "",
  email: "",
  mobile: "",
  barCouncilRegistrationNo: "",
  practiceStates: "",
  practiceCourts: "",
  lawyerEducations: "",
  lawyerSpecialisation: "",
  lawyerLanguage: "",
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case BARCOUNCILNO: {
      return {
        ...state,
        barCouncilRegistrationNo: action.payload,
      };
    }

    case PRACTICESTATES: {
      return {
        ...state,
        practiceStates: action.payload,
      };
    }

    case PRACTICECOURTS: {
      return {
        ...state,
        practiceCourts: action.payload,
      };
    }

    case LAWYEREDUCATION: {
      return {
        ...state,
        lawyerEducations: action.payload,
      };
    }

    case LAWYERSPECIALISATION: {
      return {
        ...state,
        lawyerSpecialisation: action.payload,
      };
    }

    case LAWYERLANGUAGE: {
      return {
        ...state,
        lawyerLanguage: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
