import React from "react";
import Pagination from "@mui/material/Pagination";

const CardFooter = () => {
  return (
    <div>
      <Pagination count={5} variant="outlined" shape="rounded" />
    </div>
  );
};

export default CardFooter;
