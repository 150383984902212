import React, { useEffect, useState } from "react";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { Typography } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Div from "@jumbo/shared/Div";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { CaseContainer } from "./style";
// import UploadFileIcon from "@mui/icons-material/UploadFile";
//
//
//
import { FormControlLabel, RadioGroup } from "@mui/material";
import Radio from "@mui/material/Radio";
import DatePickerComponent from "../components/DatePickerComponent";
import FormLabel from "@mui/material/FormLabel";
import AgeSelectorField from "../components/AgeSelectorField";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { CaseValidations } from "app/utils/validators";

function Case({ handleBackButton, handleNextButton }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const CaseType = ["Delhi", "Jharkhand"];
  const UnderSection = ["Section 134", "Section 143"];
  const AssignLawyer = ["Abhishek Saraswat", "Gautam Sharma"];

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [caseTitleError, setCaseTitleError] = useState(false);

  // console.log(caseType);
  // console.log(error);

  const [reset, setReset] = useState();

  const [caseTitle, setCaseTitle] = useState();
  const [caseType, setCaseType] = useState();

  const [underSection, setUnderSection] = useState();
  const [assignLawyer, setAssignLawyer] = useState();
  const [description, setDescription] = useState();

  const formik = useFormik({
    initialValues: {
      caseTitle: caseTitle,
      caseType: caseType,
      underSection: underSection,
      assignLawyer: assignLawyer,
      description: description,
    },
    validationSchema: CaseValidations,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values, "FORMIK");
      handleNextButton();
    },
  });

  if (reset) {
    // console.log("reset clicked");
    // setCaseTitle("");
    // setCaseType([]);
    // setAssignLawyer();
    // setDescription("");
    // // setUnderSection();
    // //setAssignLawyer(null);
    // setReset(false);
  }

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        paddingBottom: "30px",
      }}
    >
      {/* <CaseContainer> */}
      <Div
        sx={{
          marginLeft: { xs: "5%", md: "18%" },
          marginRight: { xs: "5%", md: "18%" },
          marginTop: "20px",
          height: "100%",
        }}
      >
        {/* <form> */}
        <TextField
          sx={{ mb: 0, mt: 0, width: "100%" }}
          id="outlined-basic"
          label="Case Title"
          required
          color="secondary"
          variant="outlined"
          value={formik.values.caseTitle}
          onChange={(e) => setCaseTitle(e.target.value)}
          error={formik.errors.caseTitle}
          name="caseTitle"
          helperText={formik.errors.caseTitle}
        />

        <Autocomplete
          onChange={(event, value) => setCaseType(value)}
          value={formik.values.caseType}
          style={{ width: "100%" }}
          multiple
          options={CaseType}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          sx={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              sx={{ mb: 0, mt: 2 }}
              {...params}
              color="secondary"
              label="Case Type*"
              placeholder="Case Type*"
              error={formik.errors.caseType}
              name="caseType"
              helperText={formik.errors.caseType}
            />
          )}
        />

        <Autocomplete
          style={{ width: "100%" }}
          multiple
          onChange={(event, value) => setUnderSection(value)}
          value={formik.values.underSection}
          options={UnderSection}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          sx={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              sx={{ mb: 0, mt: 2 }}
              {...params}
              color="secondary"
              label="Under Section*"
              placeholder="Under Section*"
              error={formik.errors.underSection}
              name="underSection"
              helperText={formik.errors.underSection}
            />
          )}
        />

        {/* assign lawyer */}

        <Autocomplete
          style={{ width: "100%" }}
          multiple
          onChange={(event, value) => setAssignLawyer(value)}
          value={formik.values.assignLawyer}
          options={AssignLawyer}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          sx={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              sx={{ mb: 0, mt: 2 }}
              {...params}
              label="Assign Lawyer*"
              color="secondary"
              placeholder="Assign Lawyer*"
              error={formik.errors.assignLawyer}
              name="assignLawyer"
              helperText={formik.errors.assignLawyer}
            />
          )}
        />

        {/* description */}
        <TextField
          sx={{ mb: 0, mt: 2, width: "100%" }}
          id="outlined-multiline-static"
          label="Write Case Description..."
          multiline
          color="secondary"
          rows={4}
          onChange={(e) => setDescription(e.target.value)}
          value={formik.values.description}
          error={formik.errors.description}
          name="description"
          helperText={formik.errors.description}
        />
        {/* </form> */}
        <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 5,
          }}
        >
          <LoadingButton
            onClick={handleBackButton}
            disabled={true}
            variant="contained"
            sx={{ width: 100, padding: 1 }}
          >
            Back
          </LoadingButton>
          <LoadingButton
            onClick={formik.handleSubmit}
            color="secondary"
            style={{
              backgroundColor: "black",
            }}
            sx={{ width: 100, padding: 1 }}
            variant="contained"
          >
            Continue
          </LoadingButton>
        </Div>
      </Div>
    </div>
    // <div
    //   style={{
    //     height: "100%",
    //     width: "100%",
    //     paddingBottom: "30px",
    //   }}
    // >
    //   {/* <CaseContainer> */}
    //   <Div
    //     sx={{
    //       //  marginLeft: { xs: "5%", md: "25%" },
    //       //  marginRight: { xs: "5%", md: "25%" },

    //       height: "100%",
    //     }}
    //   >
    //     <Div sx={{ mt: "20px" }}>
    //       {" "}
    //       {/* <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
    //         Case Title
    //       </Typography> */}
    //       <TextField
    //         sx={{ marginTop: "10px", width: "100%" }}
    //         id="outlined-basic"
    //         label="Case Title"
    //         variant="outlined"
    //         value={caseTitle}
    //         onChange={(e) => setCaseTitle(e.target.value)}
    //         // error={error.length > 0}
    //         // helperText={error.length ? error.value : ""}
    //       />
    //     </Div>

    //     {/* case type */}

    //     <Div sx={{ mt: "20px" }}>
    //       {/* <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
    //         Case Type
    //       </Typography> */}
    //       <Autocomplete
    //         onChange={(event, value) => setCaseType(value)}
    //         value={caseType?.map((value) => value)}
    //         style={{ width: "100%" }}
    //         multiple
    //         options={CaseType}
    //         disableCloseOnSelect
    //         getOptionLabel={(option) => option}
    //         renderOption={(props, option, { selected }) => (
    //           <li {...props}>
    //             <Checkbox
    //               icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
    //               checkedIcon={<CheckBoxIcon fontSize="small" />}
    //               style={{ marginRight: 8 }}
    //               checked={selected}
    //             />
    //             {option}
    //           </li>
    //         )}
    //         sx={{ width: 500 }}
    //         renderInput={(params) => (
    //           <TextField
    //             sx={{ marginTop: "10px" }}
    //             {...params}
    //             label="Case Type"
    //             placeholder="Case Type"
    //             value={selectedOptions}
    //             error={caseTitleError}
    //           />
    //         )}
    //       />
    //     </Div>

    //     {/* under section */}
    //     <Div sx={{ mt: "20px" }}>
    //       <Autocomplete
    //         style={{ width: "100%" }}
    //         multiple
    //         onChange={(event, value) => setUnderSection(value)}
    //         value={underSection?.map((value) => value)}
    //         options={UnderSection}
    //         disableCloseOnSelect
    //         getOptionLabel={(option) => option}
    //         renderOption={(props, option, { selected }) => (
    //           <li {...props}>
    //             <Checkbox
    //               icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
    //               checkedIcon={<CheckBoxIcon fontSize="small" />}
    //               style={{ marginRight: 8 }}
    //               checked={selected}
    //             />
    //             {option}
    //           </li>
    //         )}
    //         sx={{ width: 500 }}
    //         renderInput={(params) => (
    //           <TextField
    //             sx={{ marginTop: "10px" }}
    //             {...params}
    //             label="Under Section"
    //             placeholder="Under Section"
    //           />
    //         )}
    //       />
    //     </Div>

    //     {/* assign lawyer */}
    //     <Div sx={{ mt: "20px" }}>
    //       <Autocomplete
    //         style={{ width: "100%" }}
    //         multiple
    //         onChange={(event, value) => setAssignLawyer(value)}
    //         value={assignLawyer?.map((value) => value)}
    //         options={AssignLawyer}
    //         disableCloseOnSelect
    //         getOptionLabel={(option) => option}
    //         renderOption={(props, option, { selected }) => (
    //           <li {...props}>
    //             <Checkbox
    //               icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
    //               checkedIcon={<CheckBoxIcon fontSize="small" />}
    //               style={{ marginRight: 8 }}
    //               checked={selected}
    //             />
    //             {option}
    //           </li>
    //         )}
    //         sx={{ width: 500 }}
    //         renderInput={(params) => (
    //           <TextField
    //             sx={{ marginTop: "10px" }}
    //             {...params}
    //             label="Assign Lawyer"
    //             placeholder="Assign Lawyer"
    //           />
    //         )}
    //       />
    //     </Div>

    //     {/* description */}
    //     <Div sx={{ mt: "20px" }}>
    //       <TextField
    //         sx={{ marginTop: "10px", width: "100%" }}
    //         id="outlined-multiline-static"
    //         label="Description"
    //         multiline
    //         rows={4}
    //         onChange={(e) => setDescription(e.target.value)}
    //         value={description}
    //       />
    //     </Div>
    //   </Div>
    //   {/* </CaseContainer> */}
    // </div>
  );
}

export default Case;
