import * as React from "react";

import Div from "@jumbo/shared/Div";
import SearchIcon from "@mui/icons-material/Search";

import { useEffect } from "react";
import {
  Alert,
  BottomNavigation,
  BottomNavigationAction,
  Breadcrumbs,
  Button,
  ButtonGroup,
  FormControlLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import DocumentSkeletons from "./DocumentSkeletons";

import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { BookmarkAddOutlined, FilePresentOutlined } from "@mui/icons-material";

import { Card, Divider, InputBase, TextField } from "@mui/material";
import CardFooter from "../components/CardFooter";
import SearchGlobal from "app/shared/SearchGlobal";
import { useJumboTheme } from "@jumbo/hooks";
import CasePointList from "./CasePointList";
const CasePointListLayout = ({ handleScreen }) => {
  const location = useLocation();
  const [reset, setReset] = React.useState(false);
  const [allTasks, setAllTasks] = useState(false);
  const [loading, setLoading] = React.useState(false);

  const { theme } = useJumboTheme();

  useEffect(() => {
    setAllTasks(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  //validation
  const [error, setError] = React.useState([]);

  const [value, setValue] = React.useState("all");
  const [filterValue, setFilterValue] = useState("type");

  const [selectedFilter1, setSelectedFilter1] = useState();
  const [selectedFilter2, setSelectedFilter2] = useState();
  const [selectedFilter3, setSelectedFilter3] = useState();
  const [selectedFilter4, setSelectedFilter4] = useState();

  const handleChange = (event, e) => {
    setValue(e);
  };

  const handleFilterChange = (event, e) => {
    setFilterValue(e);
  };

  const [openSnackBar, setOpenSnackBar] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  return (
    <React.Fragment>
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "99%",
          mb: 1,
        }}
      >
        <Div>
          <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 0 }}>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} fontSize="inherit" />
              Home
            </Typography>

            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <BookmarkAddOutlined sx={{ fontSize: 20 }} />
              &nbsp; Case Points
            </Typography>
          </Breadcrumbs>
        </Div>
      </Div>
      {/* document archived snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackBar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        sx={{ zIndex: 1000, mt: 10 }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%", p: 2, borderRadius: "10px" }}
        >
          <Typography variant="h6"> Document Archived!</Typography>
        </Alert>
      </Snackbar>
      <Card
        sx={{
          p: 3,
          position: "relative",
        }}
      >
        <Div
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
              },
              flexWrap: "wrap",
            }}
          >
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 3,
                [theme.breakpoints.down("md")]: {
                  display: "flex",
                  mr: 0,
                  mb: 3,
                },
              }}
            >
              <Typography variant={"h5"}>All Case Points</Typography>
              <Button
                style={{ fontWeight: "700" }}
                startIcon={<AddIcon />}
                color="secondary"
                onClick={() => handleScreen(1)}
              >
                Add New
              </Button>
            </Div>

            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                width: "30%",
                [theme.breakpoints.down("md")]: {
                  display: "none",
                  mr: 0,
                  mb: 3,
                },
              }}
            >
              <Div
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 4,
                }}
              >
                <Div
                  sx={{
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <Div
                    sx={{
                      padding: (theme) => theme.spacing(0, 2),
                      height: "100%",
                      position: "absolute",
                      pointerEvents: "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 2,
                    }}
                  >
                    <SearchIcon color="secondary" />
                  </Div>
                  <InputBase
                    id="search-bar"
                    className="search"
                    placeholder="Search the client"
                    size="small"
                    sx={{
                      color: "inherit",
                      display: "flex",
                      borderRadius: 24,
                      backgroundColor: (theme) =>
                        theme.jumboComponents.JumboSearch.background,

                      "& .MuiInputBase-input": {
                        padding: (theme) => theme.spacing(1, 1, 1, 0),
                        paddingLeft: (theme) =>
                          `calc(1em + ${theme.spacing(4)})`,
                        transition: (theme) =>
                          theme.transitions.create("width"),
                        width: "100%",
                        height: 24,
                      },
                    }}
                  />
                </Div>

                {/* filter */}
                <Button
                  id="basic-button"
                  aria-controls={openMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? "true" : undefined}
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                  <Button
                    style={{ fontWeight: "700" }}
                    startIcon={<FilterAltOutlinedIcon />}
                    color="secondary"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Filter By
                  </Button>
                </Button>
              </Div>
            </Div>
          </Div>

          {/* div hidden on large and medium view */}
          <Div
            sx={{
              display: "none",
              alignItems: "center",
              width: "100%",
              [theme.breakpoints.down("md")]: {
                display: "flex",
                mr: 0,
                mt: 0,
                //   mb: 3,
              },
            }}
          >
            <Div
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Div
                sx={{
                  position: "relative",
                  width: "100%",
                }}
              >
                <Div
                  sx={{
                    padding: (theme) => theme.spacing(0, 2),
                    height: "100%",
                    position: "absolute",
                    pointerEvents: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 2,
                  }}
                >
                  <SearchIcon color="secondary" />
                </Div>
                <InputBase
                  id="search-bar"
                  className="search"
                  placeholder="Search the client"
                  size="small"
                  sx={{
                    color: "inherit",
                    display: "flex",
                    borderRadius: 24,
                    backgroundColor: (theme) =>
                      theme.jumboComponents.JumboSearch.background,

                    "& .MuiInputBase-input": {
                      padding: (theme) => theme.spacing(1, 1, 1, 0),
                      paddingLeft: (theme) => `calc(1em + ${theme.spacing(4)})`,
                      transition: (theme) => theme.transitions.create("width"),
                      width: "100%",
                      height: 24,
                    },
                  }}
                />
              </Div>

              <Button
                style={{ fontWeight: "700" }}
                startIcon={<FilterAltOutlinedIcon />}
                color="secondary"
                sx={{ whiteSpace: "nowrap" }}
              >
                Filter By
              </Button>
            </Div>
          </Div>
          <Divider sx={{ mt: 2 }} />
          <Div sx={{ mb: 10 }}>
            {loading && <DocumentSkeletons />}
            {!loading && (
              <CasePointList
                handleScreen={handleScreen}
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
              />
            )}
          </Div>
          <Div
            sx={{
              position: "absolute",
              bottom: 20,
              right: { sm: 0, lg: 25 },
            }}
          >
            <CardFooter />
          </Div>
        </Div>
      </Card>
    </React.Fragment>
  );
};

export default CasePointListLayout;
