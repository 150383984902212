import React, { useEffect, useState } from "react";
import { CardContent, TextField, Typography } from "@mui/material";
import GoogleNest from "../../../shared/widgets/GoogleNest";
import Link from "@mui/material/Link";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation } from "react-router-dom";
import Container from "@mui/material/Container";
import SwipeableTextMobileStepper from "../../components/SwipeableTextMobileStepper";
import Otp from "../otp";
import SocialButtons from "app/pages/components/SocialButtons";
import LoginFooter from "app/pages/components/LoginFooter";
import { generateOtp } from "app/Apis/Login";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Animation from "app/pages/components/Animations/Animation";

const Login = ({ disableSmLogin, editNumber }) => {
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const phoneUrl = queryParameters.get("phoneNumber");
  const [isLoading, setIsLoading] = useState(false);

  const [showOtp, setShowOtp] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");

  const handleChange = (event) => {
    setPhoneNumber(event.target.value);
  };
  const getOtp = async () => {
    if (phoneNumber) {
      const response = await generateOtp(phoneNumber);
    } else {
      const response = await generateOtp(phoneUrl);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/.test(phoneNumber)
    ) {
      setShowOtp(true);
      setError("");
      getOtp();
    } else {
      setError("Enter a valid phone number");
      setTimeout(() => {
        setError("");
      }, 4000);
    }
  };
  useEffect(() => {
    if (phoneUrl) {
      if (
        /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/.test(phoneUrl)
      ) {
        setShowOtp(true);
        getOtp();
      } else {
        setError("Enter a valid phone number");
      }
    }
    if (editNumber) {
      setPhoneNumber(editNumber);
    }
  }, [phoneUrl]);

  return (
    <>
      {isLoading ? <Animation /> : null}
      {showOtp ? (
        <Otp phoneNumber={phoneNumber ? phoneNumber : phoneUrl} />
      ) : (
        <Div
          sx={{
            width: "100%",
            height: "100vh",
            margin: "auto",
            backgroundColor: "#FFFFFF",
          }}
        >
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              flexDirection: { xs: "column", md: "row" },
              height: "100%",
              borderRadius: "0",
            }}
          >
            <Div
              sx={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Container maxWidth="sm">
                <CardContent sx={{ flex: 1, p: 2 }}>
                  <Div
                    sx={{
                      mb: 1,
                    }}
                  >
                    <Link href="https://lawinzo.com">
                      <img
                        src={`${ASSET_IMAGES}/lawinzo/logo-white.png`}
                        style={{
                          objectFit: "contain",
                          width: "20%",
                        }}
                        alt="Lawinzo"
                      />
                    </Link>
                  </Div>
                  <Typography
                    variant={"h2"}
                    sx={{
                      color: "#151513",
                      fontSize: "1rem",
                      fontWeight: "700",
                      mb: 2,
                    }}
                  >
                    Access the Lawinzo Pannel
                  </Typography>
                  <form onSubmit={handleSubmit}>
                    <Div sx={{ mb: 2 }}>
                      <label>
                        <TextField
                          fullWidth
                          id="phoneNumber"
                          label="Phone Number"
                          type="tel"
                          color="secondary"
                          onChange={handleChange}
                          value={phoneNumber}
                          inputProps={{ maxLength: 10 }}
                          required
                        />
                      </label>
                      {error && (
                        <Div sx={{ display: "flex", alignItems: "center" }}>
                          <ErrorOutlineIcon color="error" fontSize="small" />
                          <Typography
                            sx={{
                              color: "red",
                              fontSize: "12px",
                              ml: 1,
                            }}
                          >
                            {error}
                          </Typography>
                        </Div>
                      )}
                    </Div>

                    <Div sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <Div sx={{ display: "flex" }}>
                        <Typography sx={{ fontSize: "12px" }}>
                          By Proceeding, you agree to the{" "}
                          <Link
                            href="#"
                            underline="none"
                            sx={{ color: "#EABF4E" }}
                          >
                            Privacy Policy
                          </Link>{" "}
                          and{" "}
                          <Link
                            href="#"
                            underline="none"
                            sx={{ color: "#EABF4E" }}
                          >
                            Terms and conditions
                          </Link>
                        </Typography>
                      </Div>
                    </Div>
                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ mb: 4 }}
                      // onClick={getOtp}
                    >
                      Get OTP
                    </LoadingButton>
                  </form>

                  {!disableSmLogin && (
                    <React.Fragment>
                      <Typography
                        variant={"body1"}
                        mb={2}
                        sx={{ textAlign: "center", fontWeight: "700" }}
                      >
                        Or sign in with
                      </Typography>
                      <SocialButtons />
                      <LoginFooter />
                    </React.Fragment>
                  )}
                </CardContent>
              </Container>
            </Div>
            {!disableSmLogin && (
              <Div
                sx={{
                  flex: "1 1",
                  height: "100%",
                  backgroundColor: "#F5F5F5",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Div
                    sx={{
                      display: "flex",
                      minWidth: 0,
                      flex: 1,
                      flexDirection: "column",
                      color: "common.black",
                      zIndex: 1,
                      height: "100%",
                    }}
                  >
                    <Div sx={{ mb: 2 }}>
                      <SwipeableTextMobileStepper />
                    </Div>
                  </Div>
                </CardContent>
              </Div>
            )}
          </Div>
        </Div>
      )}
    </>
  );
};

export default Login;
