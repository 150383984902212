import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Div from "@jumbo/shared/Div";
import Case from "./Case";
import Hearing from "./Hearing";
import Client from "./Client";
import OppositeClient from "./OppositeClient";
import Witness from "./Witness";
import { LoadingButton } from "@mui/lab";
import { useEffect } from "react";
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { GavelOutlined } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import ModalScreen from "./ModalScreen";
import HomeIcon from "@mui/icons-material/Home";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import GrainIcon from "@mui/icons-material/Grain";

import NoCase from "./NoCase";
const AddCase = ({ setAddCase }) => {
  const location = useLocation();
  const [reset, setReset] = React.useState(false);
  const resetValue = () => {
    setReset(true);
  };

  // useEffect(() => {}, [reset]);

  //case variables
  const [caseTitle, setCaseTitle] = React.useState(null);
  const [caseType, setCaseType] = React.useState(null);
  const [underSection, setUnderSection] = React.useState();
  const [assignLawyer, setAssignLawyer] = React.useState();
  const [description, setDescription] = React.useState();

  //hearing variables
  const [startingDate, setStartingDate] = React.useState(null);
  const [currentDate, setCurrentDate] = React.useState();
  const [casePoints, setCasePoints] = React.useState();
  const [documentName, setDocumentName] = React.useState();
  const [documentUploaded, setDocumentUploaded] = React.useState([]);
  const [hearingDescription, setHearingDescription] = React.useState();

  const [value, setValue] = React.useState(0);
  const [buttonValue, setButtonValue] = React.useState(0);

  console.log("button is ", buttonValue);
  console.log("value rn is", value);

  const handleNextButton = () => {
    // ValidateValues();

    // if (error.length > 0) {
    if (value !== 4) {
      setValue((prevButtonValue) => prevButtonValue + 1);
    }
    setButtonValue((prevButtonValue) => prevButtonValue + 1);
    // }
  };

  console.log("button value ", buttonValue);

  const handleBackButton = () => {
    console.log("hello");
    if (value !== 0) {
      setButtonValue((prevButtonValue) => prevButtonValue - 1);
      setValue((prevButtonValue) => prevButtonValue - 1);
    }
  };

  //modals
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  console.log("open value is ", open);

  //validation
  const [error, setError] = React.useState([]);

  return (
    <>
      <Div sx={{ height: "100%", width: "100%" }}>
        <Div sx={{ mb: 2, mt: -3 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} fontSize="inherit" />
              Home
            </Typography>

            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <GavelOutlined sx={{ fontSize: 20 }} />
              My Cases
            </Typography>
          </Breadcrumbs>
        </Div>
        <Card
          sx={{
            backgroundColor: "white",
            height: "100%",
            position: "relative",
            paddingTop: "10px",
          }}
        >
          <CardContent>
            <Div
              sx={{
                width: "100%",
                position: "relative",
                height: "fit-content",
                mt: -2,
                mb: 8,
              }}
            >
              <Div
                sx={{
                  position: "absolute",
                  borderBottom: "1px solid #D3D3D3 ",
                  width: "100%",
                  pt: 0,
                  pb: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" color="text.primary">
                  My Cases
                </Typography>

                <Typography
                  variant="h6"
                  color="text.primary"
                  sx={{ cursor: "pointer" }}
                  onClick={resetValue}
                >
                  Reset
                </Typography>
              </Div>
            </Div>
            {/* breadcrum */}
            <Div
              sx={{
                pl: { sm: "0", lg: "11%" },
                pr: { sm: "0", lg: "11%" },
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Div
                sx={{
                  maxWidth: { xs: 350, sm: 600, md: 700, lg: 800 },
                  mt: -1,
                }}
              >
                <Tabs
                  indicatorColor="secondary"
                  textColor="secondary"
                  value={value}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="scrollable force tabs example"
                >
                  <Tab
                    sx={{
                      fontSize: { xs: "14px", md: "14px" },
                      fontWeight: "800",
                    }}
                    label="Case"
                  />
                  <Tab
                    sx={{
                      fontSize: { xs: "14px", md: "14px" },
                      fontWeight: "800",
                    }}
                    label="Hearing"
                  />
                  <Tab
                    sx={{
                      fontSize: { xs: "14px", md: "14px" },
                      fontWeight: "800",
                    }}
                    label="Client"
                  />
                  <Tab
                    sx={{
                      fontSize: { xs: "14px", md: "14px" },
                      fontWeight: "800",
                    }}
                    label="Opposite Client"
                  />
                  <Tab
                    sx={{
                      fontSize: { xs: "14px", md: "16px" },
                      fontWeight: "800",
                    }}
                    label="Witness"
                  />
                </Tabs>
              </Div>
              <Div sx={{ width: "110%" }}>
                {value === 0 ? (
                  <Case
                    handleBackButton={handleBackButton}
                    handleNextButton={handleNextButton}
                  />
                ) : value === 1 ? (
                  <Hearing
                    handleBackButton={handleBackButton}
                    handleNextButton={handleNextButton}
                  />
                ) : value === 2 ? (
                  <Client
                    handleBackButton={handleBackButton}
                    handleNextButton={handleNextButton}
                  />
                ) : value === 3 ? (
                  <OppositeClient
                    handleBackButton={handleBackButton}
                    handleNextButton={handleNextButton}
                  />
                ) : value === 4 ? (
                  <Witness
                    handleBackButton={handleBackButton}
                    handleNextButton={handleNextButton}
                    open={open}
                    setOpen={setOpen}
                    setAddCase={setAddCase}
                  />
                ) : (
                  ""
                )}
              </Div>
            </Div>
          </CardContent>
        </Card>
      </Div>
    </>
  );
};

export default AddCase;
