import {
  BARCOUNCILNO,
  PRACTICESTATES,
  PRACTICECOURTS,
  LAWYEREDUCATION,
  LAWYERSPECIALISATION,
  LAWYERLANGUAGE,
} from "../../utils/constants/LawinzoUpdatedInputs";

export const UserBarCouncil = (number) => {
  return (dispatch) => {
    dispatch({ type: BARCOUNCILNO, payload: number });
  };
};

export const UserPracticeStates = (states) => {
  return (dispatch) => {
    dispatch({ type: PRACTICESTATES, payload: states });
  };
};
export const UserPracticeCourts = (courts) => {
  return (dispatch) => {
    dispatch({ type: PRACTICECOURTS, payload: courts });
  };
};

export const UserLawyerEducation = (education) => {
  return (dispatch) => {
    dispatch({ type: LAWYEREDUCATION, payload: education });
  };
};

export const UserLawyerSpecialisation = (specialisation) => {
  return (dispatch) => {
    dispatch({ type: LAWYERSPECIALISATION, payload: specialisation });
  };
};

export const UserLawyerLanguage = (language) => {
  return (dispatch) => {
    dispatch({ type: LAWYERLANGUAGE, payload: language });
  };
};
