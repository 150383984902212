import React from "react";
import { Card, Paper, Skeleton } from "@mui/material";
import { teamData } from "./CardData";
import Div from "@jumbo/shared/Div";

const TeamSkeletons = () => {
  return (
    <Div>
      {teamData.map((team) => (
        <>
          <Paper sx={{ mb: 2, p: 2 }} key={team.id}>
            <Skeleton />
            <Skeleton animation={"wave"} />
            <Skeleton animation={false} />
          </Paper>
        </>
      ))}
    </Div>
  );
};

export default TeamSkeletons;
