import * as React from "react";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Box } from "@mui/system";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Div from "@jumbo/shared/Div";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FormGroup from "@mui/material/FormGroup";
import { useFormik } from "formik";
import { documentValidations } from "app/utils/validators";
import { LoadingButton } from "@mui/lab";

const DocumentDetails = ({
  setAllTasks,
  value,
  setValue,
  setButtonValue,
  allTasks,
  AllDocumentsDisplay,
  handleBackButton,
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const hiddenFileInput = React.useRef(null);

  const [fieldCase, setFieldCase] = React.useState([]);
  const [fieldLawyer, setFieldLawyer] = React.useState([]);
  const [fieldClient, setFieldClient] = React.useState([]);
  const [fieldDescription, setFieldDescription] = React.useState();
  const [documentName, setDocumentName] = React.useState();

  const formik = useFormik({
    initialValues: {
      case: fieldCase,
      caseLawyer: fieldLawyer,
      client: fieldClient,

      documentName: documentName,
      documentDescription: fieldDescription,
    },
    validationSchema: documentValidations,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values, "FORMIK");
      AllDocumentsDisplay();
    },
  });
  const Case = ["Case 1", "Case 2"];
  const Lawyer = ["Assigner 1", "Assigner 2"];
  const Client = ["Assigned 1", "Assigned 2"];

  const [documentUploaded, setDocumentUploaded] = React.useState([]);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    console.log(fileUploaded);

    setDocumentUploaded((state) => [
      ...state,
      URL.createObjectURL(event.target.files[0]),
    ]);
  };

  console.log(documentUploaded);

  const removeDocument = (deletedUrl) => {
    const newDocumentUploaded = documentUploaded?.filter((document) => {
      return document !== deletedUrl;
    });

    setDocumentUploaded(newDocumentUploaded);
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        paddingBottom: "30px",
      }}
    >
      {/* <CaseContainer> */}
      <Div
        sx={{
          marginLeft: { xs: "5%", md: "18%" },
          marginRight: { xs: "5%", md: "18%" },

          height: "100%",
        }}
      >
        {/* case starting date */}
        <Typography variant="h6" sx={{ mt: "0px" }}>
          Document Details*
        </Typography>

        <Autocomplete
          style={{ width: "100%" }}
          value={formik.values.case}
          //  onChange={(event, value) => setFieldCase(value)}
          onChange={(event, value) => setFieldCase(value)}
          multiple
          // id="checkboxes-tags-demo"
          options={Case}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          sx={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              sx={{ marginTop: "10px" }}
              {...params}
              label="Case*"
              placeholder="Case*"
              color="secondary"
              error={formik.errors.case}
              name="case"
              helperText={formik.errors.case}
            />
          )}
        />

        {/*case lawuer*/}

        <Autocomplete
          style={{ width: "100%" }}
          caseLawyer
          value={formik.values.caseLawyer}
          //  onChange={(event, value) => setFieldCase(value)}
          onChange={(event, value) => setFieldLawyer(value)}
          multiple
          // id="checkboxes-tags-demo"
          options={Lawyer}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          sx={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              sx={{ marginTop: "10px" }}
              {...params}
              label="Case Lawyer*"
              placeholder="Case Lawyer*"
              color="secondary"
              error={formik.errors.caseLawyer}
              name="caseLawyer"
              helperText={formik.errors.caseLawyer}
            />
          )}
        />
        {/* client */}

        <Autocomplete
          style={{ width: "100%" }}
          value={formik.values.client}
          //  onChange={(event, value) => setFieldCase(value)}

          onChange={(event, value) => setFieldClient(value)}
          multiple
          // id="checkboxes-tags-demo"
          options={Client}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          sx={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              sx={{ marginTop: "10px" }}
              {...params}
              label="Client*"
              placeholder="Client*"
              color="secondary"
              error={formik.errors.client}
              name="client"
              helperText={formik.errors.client}
            />
          )}
        />

        {/* attached documents */}

        <Typography variant="h6" sx={{ mb: 0, mt: 2 }}>
          Upload Document*
        </Typography>
        {/* document name */}

        {/* <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
              Document Name
            </Typography> */}
        <TextField
          sx={{ mb: 0, mt: 2, width: "100%" }}
          id="outlined-basic"
          label="Document Name*"
          variant="outlined"
          color="secondary"
          value={formik.values.documentName}
          error={formik.errors.documentName}
          name="documentName"
          helperText={formik.errors.documentName}
          onChange={(e) => setDocumentName(e.target.value)}
        />

        {/* upload */}

        {/* <Div sx={{  width: "100%" }}> */}
        {/* <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
              Upload Document
            </Typography> */}
        {/* </Div> */}

        <Box
          sx={{
            width: "100%",
            height: "50.71px",
            mb: 0,
            mt: 2,
            border: "1px solid #cdced1",
            borderRadius: "3px",

            // padding: "17px 14px 17px 14px",
            // "&:hover": {
            //   backgroundColor: "primary.main",
            //   opacity: [0.9, 0.8, 0.7],
            // },
            display: "flex",
            alignItems: "center",
          }}
        >
          <Div sx={{ display: "flex", alignItems: "center" }}>
            <UploadFileIcon sx={{ fontSize: "40px" }} onClick={handleClick} />
            <Typography sx={{ fontSize: "14px", color: "#929faf" }}>
              Upload Document
            </Typography>
          </Div>
        </Box>

        <input
          style={{ mb: 0, mt: 2, width: "30vw" }}
          accept="image/*"
          multiple
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          hidden
        />
        <Typography sx={{ color: "#929faf", fontSize: "12px", mt: "2px" }}>
          File Should be jpg, jpeg, png, Pdf
        </Typography>
        {/* uploaded image */}
        <Div
          sx={{
            display: "flex",
            alignItems: "center",
            overflowX: "scroll",
            width: "100%",
          }}
        >
          {documentUploaded &&
            documentUploaded.map((e) => (
              <Div
                sx={{
                  position: "relative",
                  // backgroundColor: "green",
                  mr: 2,
                }}
              >
                <img
                  style={{
                    width: { md: "5vw" },
                    height: "10vh",
                    borderRadius: "50%",
                    objectFit: "contain",
                    marginRight: "10px",
                  }}
                  src={e}
                  alt=""
                />
                <CloseIcon
                  onClick={() => removeDocument(e)}
                  sx={{ position: "absolute", right: 2 }}
                />
              </Div>
            ))}
        </Div>

        {/* description */}
        <Typography variant="h6" sx={{ mb: 0, mt: 2 }}>
          Document Description*
        </Typography>
        <TextField
          sx={{ mb: 0, mt: 2, width: "100%" }}
          id="outlined-multiline-static"
          label="Write Description here*"
          color="secondary"
          placeholder="Write Description here*"
          multiline
          rows={4}
          value={formik.values.documentDescription}
          onChange={(e) => setFieldDescription(e.target.value)}
          error={formik.errors.documentDescription}
          name="documentDescription"
          helperText={formik.errors.documentDescription}
        />

        <Div sx={{ display: "flex", alignItems: "center" }}>
          {/* <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Label"
          /> */}
          <Checkbox color="secondary" checked />

          <Typography>Admissible Evidence</Typography>
        </Div>
      </Div>

      <Div
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
          paddingLeft: { xs: "0%", md: "18%" },
          paddingRight: { xs: "0%", md: "18%" },
          mt: 5,
        }}
      >
        <LoadingButton
          onClick={formik.handleSubmit}
          style={{
            backgroundColor: "black",
          }}
          sx={{ width: 100, padding: 1 }}
          variant="contained"
        >
          Add
        </LoadingButton>
      </Div>
    </div>
  );
};

export default DocumentDetails;
