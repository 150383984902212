import React, { useState } from "react";
import { FormControlLabel, RadioGroup, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Radio from "@mui/material/Radio";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useFormik } from "formik";
import { PersonalDetailsValidations } from "app/utils/validators";

const PersonalDetails = ({ handleBackButton, handleNextButton }) => {
  const [fullName, setFullName] = useState();
  const [age, setAge] = useState();
  const [gender, setGender] = useState();
  const [email, setEmail] = useState();
  const [date, setDate] = useState();
  const [phone, setPhone] = useState();

  const formik = useFormik({
    initialValues: {
      fullName: fullName,
      email: email,
      age: age,
      gender: gender,
      //  date: date,
      phone: phone,
    },
    validationSchema: PersonalDetailsValidations,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values, "FORMIK");
      handleNextButton();
    },
  });

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        paddingBottom: "30px",
      }}
    >
      {/* <CaseContainer> */}
      <Div
        sx={{
          marginLeft: { xs: "0", md: "18%" },
          marginRight: { xs: "0", md: "18%" },

          height: "100%",
        }}
      >
        {/* <form> */}
        <TextField
          fullWidth
          label="Full Name"
          type="text"
          color="secondary"
          required
          value={formik.values.fullName}
          onChange={(e) => setFullName(e.target.value)}
          error={formik.errors.fullName}
          name="fullName"
          helperText={formik.errors.fullName}
          sx={{ mb: 2 }}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Choose the Date"
            inputFormat="DD/MM/YYYY"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            name="date"
            renderInput={(params) => (
              <TextField
                fullWidth
                name="date"
                color="secondary"
                required
                sx={{ mb: 2 }}
                {...params}
              />
            )}
          />
        </LocalizationProvider>

        <TextField
          fullWidth
          label="Age"
          type="text"
          color="secondary"
          required
          value={formik.values.age}
          onChange={(e) => setAge(e.target.value)}
          error={formik.errors.age}
          name="age"
          helperText={formik.errors.age}
          sx={{ mb: 2 }}
        />

        <Div sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" sx={{ mr: 3 }}>
            Select Gender:
          </Typography>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            name="gender"
            value={formik.values.gender}
            onChange={(e, value) => setGender(value)}
          >
            <FormControlLabel
              value="female"
              control={<Radio color="secondary" />}
              label="Female"
            />
            <FormControlLabel
              value="male"
              control={<Radio color="secondary" />}
              label="Male"
            />
            <FormControlLabel
              value="other"
              control={<Radio color="secondary" />}
              label="Other"
            />
          </RadioGroup>
        </Div>
        {formik.errors.gender && (
          <Typography
            sx={{ fontSize: "10px", color: "#E73145", ml: 1.8, mt: -1 }}
          >
            Please Select atleast one value!
          </Typography>
        )}

        <TextField
          fullWidth
          name="email"
          label="Email"
          type="text"
          value={formik.values.email}
          // onChange={formik.handleChange}
          onChange={(e) => setEmail(e.target.value)}
          error={formik.errors.email}
          helperText={formik.errors.email}
          color="secondary"
          required
          sx={{ mb: 2, mt: 2 }}
        />
        <TextField
          fullWidth
          name="phoneNumber"
          label="Phone Number"
          type="tel"
          value={formik.values.phone}
          // onChange={formik.handleChange}
          onChange={(e) => setPhone(e.target.value)}
          error={formik.errors.phone}
          helperText={formik.errors.phone}
          color="secondary"
          required
          sx={{ mb: 2 }}
          inputProps={{ maxLength: 10 }}
        />
        {/* </form> */}
        <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          <LoadingButton
            onClick={handleBackButton}
            disabled
            variant="contained"
            sx={{ width: 100, padding: 1 }}
          >
            Back
          </LoadingButton>
          <LoadingButton
            onClick={formik.handleSubmit}
            color="secondary"
            style={{
              backgroundColor: "black",
            }}
            sx={{ width: 100, padding: 1 }}
            variant="contained"
          >
            Continue
          </LoadingButton>
        </Div>
      </Div>
    </div>
  );
};

export default PersonalDetails;
