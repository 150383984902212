import React from "react";
import { CaseData } from "./CaseData";
import CaseCard from "./CaseCard";
const CaseList = () => {
  return (
    <React.Fragment>
      {CaseData.map((team) => (
        <CaseCard Key={team.id} item={team} />
      ))}
    </React.Fragment>
  );
};

export default CaseList;
