import * as React from "react";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Button, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Box } from "@mui/system";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Div from "@jumbo/shared/Div";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { HearingValidations } from "app/utils/validators";

const Hearing = ({ handleBackButton, handleNextButton }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const hiddenFileInput = React.useRef(null);

  const [startingDate, setStartingDate] = React.useState();
  const [casePoints, setCasePoints] = React.useState();
  const [currentDate, setCurrentDate] = React.useState();
  const [documentName, setDocumentName] = React.useState();
  const [hearingDescription, setHearingDescription] = React.useState();
  const [documentUploaded, setDocumentUploaded] = React.useState();

  const formik = useFormik({
    initialValues: {
      startingDate: startingDate,
      casePoints: casePoints,
      currentDate: currentDate,
      documentName: documentName,
      hearingDescription: hearingDescription,
    },
    validationSchema: HearingValidations,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values, "FORMIK");
      handleNextButton();
    },
  });

  console.log(startingDate);
  console.log(casePoints);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    console.log(fileUploaded);

    setDocumentUploaded((state) => [
      ...state,
      URL.createObjectURL(event.target.files[0]),
    ]);
  };

  console.log(documentUploaded);

  const removeDocument = (deletedUrl) => {
    const newDocumentUploaded = documentUploaded?.filter((document) => {
      return document !== deletedUrl;
    });

    setDocumentUploaded(newDocumentUploaded);
  };

  console.log(startingDate);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        paddingBottom: "30px",
      }}
    >
      {/* <CaseContainer> */}
      <Div
        sx={{
          marginLeft: { xs: "5%", md: "18%" },
          marginRight: { xs: "5%", md: "18%" },
          marginTop: "20px",
          height: "100%",
        }}
      >
        {/* case starting date */}

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <DatePicker
              label="Case Starting Date"
              inputFormat="DD/MM/YYYY"
              value={formik.values.startingDate}
              onChange={(newValue) => setStartingDate(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  //  error={formik.errors.startingDate}
                  name="startingDate"
                  color="secondary"
                  // helperText={formik.errors.startingDate}
                  sx={{ marginTop: "10px", width: "100%" }}
                />
              )}
            />
          </Stack>
        </LocalizationProvider>
        {formik.errors.startingDate && (
          <Typography
            sx={{ fontSize: "10px", color: "#E73145", ml: 1.8, mt: 0.3 }}
          >
            Please choose a date!
          </Typography>
        )}

        {/* case current date */}
        {/* case starting date */}

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <DatePicker
              label="Case Starting Date"
              inputFormat="DD/MM/YYYY"
              value={formik.values.currentDate}
              onChange={(newValue) => setCurrentDate(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // error={formik.errors.currentDate}
                  name="currentDate"
                  color="secondary"
                  // helperText={formik.errors.currentDate}
                  sx={{ mb: 0, mt: 2, width: "100%" }}
                />
              )}
            />
          </Stack>
        </LocalizationProvider>
        {formik.errors.startingDate && (
          <Typography
            sx={{ fontSize: "10px", color: "#E73145", ml: 1.8, mt: 0.3 }}
          >
            Please choose a date!
          </Typography>
        )}

        {/* case points */}

        <TextField
          sx={{ mb: 0, mt: 2, width: "100%" }}
          id="outlined-basic"
          label="Case Points*"
          variant="outlined"
          value={formik.values.casePoints}
          error={formik.errors.casePoints}
          name="casePoints"
          color="secondary"
          helperText={formik.errors.casePoints}
          onChange={(e) => setCasePoints(e.target.value)}
        />

        {/* attached documents */}

        <Typography variant="h6" sx={{ mb: 0, mt: 2 }}>
          Attach Case Documents
        </Typography>
        {/* document name */}

        <TextField
          sx={{ mb: 0, mt: 2, width: "100%" }}
          id="outlined-basic"
          label="Document Name*"
          variant="outlined"
          value={formik.values.documentName}
          onChange={(e) => setDocumentName(e.target.value)}
          error={formik.errors.documentName}
          name="documentName"
          color="secondary"
          helperText={formik.errors.documentName}
        />

        <Box
          sx={{
            // marginTop: "10px",
            mb: 0,
            mt: 2,
            width: "100%",
            height: "50.71px",

            border: "1px solid #cdced1",
            borderRadius: "3px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Div sx={{ display: "flex", alignItems: "center" }}>
            <UploadFileIcon sx={{ fontSize: "40px" }} onClick={handleClick} />
            <Typography sx={{ fontSize: "14px", color: "#929faf" }}>
              Upload Document
            </Typography>
          </Div>
        </Box>

        <input
          style={{ marginTop: "10px", width: "30vw" }}
          accept="image/*"
          multiple
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          hidden
        />
        <Typography sx={{ color: "#929faf", fontSize: "12px", mt: "2px" }}>
          File Should be jpg, jpeg, png, Pdf
        </Typography>
        {/* uploaded image */}
        <Div
          sx={{
            display: "flex",
            alignItems: "center",
            overflowX: "scroll",
            width: "100%",
          }}
        >
          {documentUploaded &&
            documentUploaded.map((e) => (
              <Div
                sx={{
                  position: "relative",
                  // backgroundColor: "green",
                  mr: 2,
                }}
              >
                <img
                  style={{
                    width: { md: "5vw" },
                    height: "10vh",
                    borderRadius: "50%",
                    objectFit: "contain",
                    marginRight: "10px",
                  }}
                  src={e}
                  alt=""
                />
                <CloseIcon
                  onClick={() => removeDocument(e)}
                  sx={{ position: "absolute", right: 2 }}
                />
              </Div>
            ))}
        </Div>

        {/* description */}

        <TextField
          sx={{ mb: 0, mt: 2, width: "100%" }}
          id="outlined-multiline-static"
          label="Description"
          placeholder="Write Document description here"
          multiline
          color="secondary"
          rows={4}
          value={formik.values.hearingDescription}
          error={formik.errors.hearingDescription}
          name="hearingDescription"
          helperText={formik.errors.hearingDescription}
          onChange={(e) => setHearingDescription(e.target.value)}
        />
        {/* </Div> */}

        <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 5,
          }}
        >
          <LoadingButton
            onClick={handleBackButton}
            //   disabled={values !== 0 ? false : true}
            variant="contained"
            sx={{ width: 100, padding: 1 }}
          >
            Back
          </LoadingButton>
          <LoadingButton
            onClick={formik.handleSubmit}
            color="secondary"
            style={{
              backgroundColor: "black",
            }}
            sx={{ width: 100, padding: 1 }}
            variant="contained"
          >
            Continue
          </LoadingButton>
        </Div>
      </Div>
    </div>
  );
};

export default Hearing;
