import * as React from "react";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Snackbar,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Box } from "@mui/system";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Div from "@jumbo/shared/Div";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FormGroup from "@mui/material/FormGroup";
import { useFormik } from "formik";
import { casePointValidations } from "app/utils/validators";
import { LoadingButton } from "@mui/lab";

const CasePointDetails = ({ handleBackButton, value, AllDocumentsDisplay }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const hiddenFileInput = React.useRef(null);

  const Case = ["Case 1", "Case 2"];
  const Lawyer = ["Assigner 1", "Assigner 2"];
  const Client = ["Assigned 1", "Assigned 2"];
  const LinkedDocument = ["Document 1", "Document 2"];

  const [fieldCase, setFieldCase] = React.useState();
  const [fieldLawyer, setFieldLawyer] = React.useState();
  const [fieldClient, setFieldClient] = React.useState();
  const [fieldDescription, setFieldDescription] = React.useState();
  const [linkedDocument, setLinkedDocument] = React.useState();
  const [lawPoint, setLawPoint] = React.useState();
 

  

  const formik = useFormik({
    initialValues: {
      case: fieldCase,
      caseLawyer: fieldLawyer,
      client: fieldClient,
      lawPoint: lawPoint,
      linkedDocuments: linkedDocument,
      casePointDescription: fieldDescription,
    },
    validationSchema: casePointValidations,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values, "FORMIK");
      AllDocumentsDisplay();
    },
  });

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        paddingBottom: "30px",
      }}
    >
      
      {/* <CaseContainer> */}
      <Div
        sx={{
          marginLeft: { xs: "5%", md: "18%" },
          marginRight: { xs: "5%", md: "18%" },

          height: "100%",
        }}
      >
        {/* case starting date */}
        <Typography variant="h6" sx={{ mt: "0px" }}>
          Case Point Details*
        </Typography>

        <Autocomplete
          style={{ width: "100%" }}
          value={formik.values.case}
          onChange={(event, value) => setFieldCase(value)}
          multiple
          // id="checkboxes-tags-demo"
          options={Case}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          sx={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              sx={{ marginTop: "10px" }}
              {...params}
              label="Case*"
              placeholder="Case*"
              color="secondary"
              error={formik.errors.case}
              name="case"
              helperText={formik.errors.case}
            />
          )}
        />

        {/*case lawuer*/}

        <Autocomplete
          style={{ width: "100%" }}
          value={formik.values.caseLawyer}
          onChange={(event, value) => setFieldLawyer(value)}
          multiple
          // id="checkboxes-tags-demo"
          options={Lawyer}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          sx={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              sx={{ marginTop: "10px" }}
              {...params}
              label="Case Lawyer*"
              placeholder="Case Lawyer*"
              color="secondary"
              error={formik.errors.caseLawyer}
              name="caseLawyer"
              helperText={formik.errors.caseLawyer}
            />
          )}
        />
        {/* client */}

        <Autocomplete
          style={{ width: "100%" }}
          value={formik.values.client}
          onChange={(event, value) => setFieldClient(value)}
          multiple
          // id="checkboxes-tags-demo"
          options={Client}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          sx={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              sx={{ marginTop: "10px" }}
              {...params}
              label="Client*"
              placeholder="Client*"
              color="secondary"
              error={formik.errors.client}
              name="client"
              helperText={formik.errors.client}
            />
          )}
        />
        {/* law points */}
        <TextField
          sx={{ mb: 0, mt: 2, width: "100%" }}
          id="outlined-basic"
          label="Law Point*"
          variant="outlined"
          color="secondary"
          value={lawPoint}
          onChange={(e) => setLawPoint(e.target.value)}
          error={formik.errors.lawPoint}
          name="lawPoint"
          helperText={formik.errors.lawPoint}
        />

        {/* linked documents */}
        <Autocomplete
          style={{ width: "100%" }}
          value={formik.values.linkedDocuments}
          onChange={(event, value) => setLinkedDocument(value)}
          multiple
          // id="checkboxes-tags-demo"
          options={LinkedDocument}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          sx={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              sx={{ marginTop: "10px" }}
              {...params}
              label="Linked Documents*"
              placeholder="Linked Documents*"
              color="secondary"
              error={formik.errors.linkedDocuments}
              name="linkedDocuments"
              helperText={formik.errors.linkedDocuments}
            />
          )}
        />

        {/* description */}

        <TextField
          sx={{ mb: 0, mt: 2, width: "100%" }}
          id="outlined-multiline-static"
          label="Write Description here*"
          color="secondary"
          placeholder="Write Description here*"
          multiline
          rows={4}
          value={formik.values.casePointDescription}
          onChange={(e) => setFieldDescription(e.target.value)}
          error={formik.errors.casePointDescription}
          name="casePointDescription"
          helperText={formik.errors.casePointDescription}
        />

        <Div sx={{ display: "flex", alignItems: "center" }}></Div>
      </Div>
      <Div
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
          paddingLeft: { xs: "0%", md: "18%" },
          paddingRight: { xs: "0%", md: "18%" },
          mt: 5,
        }}
      >
        <LoadingButton
          onClick={formik.handleSubmit}
          style={{
            backgroundColor: "black",
          }}
          sx={{ width: 100, padding: 1 }}
          variant="contained"
        >
          Add
        </LoadingButton>
      </Div>
    </div>
  );
};

export default CasePointDetails;
