import Div from "@jumbo/shared/Div";
import React, { useEffect, useState } from "react";

import { NoCaseContainer } from "../MyCases/style";
import NoCaseImage from "../../ImageAssets/NoCaseImage.png";
// import NoCaseImage from "../../ImageAssets/NoCaseImage.png";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import AddTask from "./AddTask";
function NoTask() {
  const [addCase, setAddCase] = useState(false);

  if (addCase) {
    return <AddTask />;
  } else {
    return (
      <NoCaseContainer>
        <Div
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Div
            sx={{ width: { xs: "60%", sm: "30%", md: "150px" }, height: "30%" }}
          >
            <img
              src={NoCaseImage}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              alt=""
            />
          </Div>

          <Typography
            sx={{
              fontSize: { xs: "18px", md: "24px" },
              textAlign: "center",
              mt: 2,
            }}
          >
            You haven't added any Task yet!
          </Typography>
          <Div
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              mt: 4,
            }}
          >
            {/* <Link to="/lawyers/addcases" style={{ textDecoration: "none" }}> */}
            <Button
              variant="contained"
              sx={{ backgroundColor: "secondary.main", mb: 2, width: "150px" }}
              onClick={() => setAddCase(true)}
            >
              Add Task
            </Button>
            {/* </Link> */}
            <Typography sx={{ mb: 2 }}>Or</Typography>
            <Typography
              sx={{
                fontSize: { xs: "18px", md: "20px" },
                color: "secondary.main",
                fontWeight: 500,
              }}
            >
              Import Data
            </Typography>
          </Div>
        </Div>
      </NoCaseContainer>
    );
  }
}

export default NoTask;
