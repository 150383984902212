import React, { useEffect, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";

import Screen1 from "./Screen1";
import Screen2 from "./Screen2";
import Screen3 from "./Screen3";
import Div from "@jumbo/shared/Div";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Onboarding() {
  const navigate = useNavigate();
  const profileStatus = useSelector(
    (state) => state.onboardingInputs.profileStatus
  );

  const assignedRole = useSelector(
    (state) => state.onboardingInputs.assignedRole
  );

  const name = useSelector((state) => state.onboardingInputs.fullName);

  const email = useSelector((state) => state.onboardingInputs.emailId);

  console.log("name and email ", name, email);

  useEffect(() => {
    if (assignedRole === "ROLE_CLIENT") navigate("/clients/dashboards");

    if (profileStatus === 2) {
      if (assignedRole === "ROLE_CLIENT" || assignedRole === "client")
        navigate("/clients/dashboards");
      else if (assignedRole === "ROLE_LAWYER" || assignedRole === "lawyer") {
        navigate("/lawyers/dashboards");
      }
    }
  }, [profileStatus]);

  const [activeStep, setActiveStep] = React.useState(
    assignedRole === "ROLE_MEMBER" ? -1 + 2 : profileStatus + 2
  );
  const [screenState, setScreenState] = useState(
    assignedRole === "ROLE_MEMBER" ? -1 + 2 : profileStatus + 2
  );

  const [progress, setProgress] = React.useState(activeStep * 33.333);

  console.log("progress is ", progress);
  const progressIncrement = () => {
    if (activeStep !== 4) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);

      setScreenState(screenState + 1);
      setProgress(progress + 33);
    } else if (activeStep === 4) setScreenState(screenState + 1);
  };

  const progressDecrement = () => {
    console.log(activeStep);
    if (activeStep !== 1) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setScreenState(screenState - 1);
      setProgress(progress - 33);
    }
  };

  console.log(screenState, activeStep);

  return (
    <Div
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingTop: "40px",
        paddingBottom: "40px",
      }}
    >
      <Div
        sx={{
          ml: { lg: "30%", xs: "10%", md: "20%" },
          mr: { lg: "30%", xs: "10%", md: "20%" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Div sx={{ width: "100%" }}>
          <Typography sx={{fontSize:"20px",fontWeight:"600"}}>
            Tell us more about you, before unlocking the features
          </Typography>
          <Typography sx={{ mt: 1 }}>{activeStep} of 3</Typography>

          <Div sx={{ width: "100%", mt: 3 }}>
            <LinearProgress
              sx={{ backgroundColor: "secondary.main" }}
              variant="determinate"
              value={progress}
            />
          </Div>
        </Div>

        {screenState === 1 ? (
          <Screen1
            activeStep={activeStep}
            progressIncrement={progressIncrement}
            progressDecrement={progressDecrement}
            screenState={screenState}
          />
        ) : screenState === 2 ? (
          <Screen2
            activeStep={activeStep}
            progressIncrement={progressIncrement}
            progressDecrement={progressDecrement}
            screenState={screenState}
          />
        ) : (
          <Screen3
            activeStep={activeStep}
            progressIncrement={progressIncrement}
            progressDecrement={progressDecrement}
            screenState={screenState}
          />
        )}
      </Div>
      {/* </OnboardingContainer> */}
    </Div>
  );
}

export default Onboarding;
