import styled from "styled-components";
import Div from "@jumbo/shared/Div";

export const OnboardingSuccessContainer = styled.div`
  /* background-color: #eabf4e; */
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
