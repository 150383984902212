// import {
//   SIDEBAR_ANCHOR_POSITIONS,
//   SIDEBAR_SCROLL_TYPES,
//   SIDEBAR_STYLES,
//   SIDEBAR_VARIANTS,
//   SIDEBAR_VIEWS,
// } from "@jumbo/utils/constants/layout";

// const layoutConfig = {
//   sidebar: {
//     // open: true, //if true then sidebar will be expanded otherwise collapsed
//     // hide: false, //if hide is true then sidebar will not render at all
//     // variant: SIDEBAR_VARIANTS.PERSISTENT, //one of PERSISTENT, TEMPORARY OR PERMANENT
//     // style: SIDEBAR_STYLES.FULL_HEIGHT, //One of FULL_HEIGHT/CLIPPED_UNDER_HEADER
//     // view: SIDEBAR_VIEWS.FULL, //One of FULL/MINI
//     // scrollType: SIDEBAR_SCROLL_TYPES.FIXED, //One of FIXED/DEFAULT
//     // anchor: SIDEBAR_ANCHOR_POSITIONS.LEFT, //One of LEFT/RIGHT/TOP/BOTTOM
//     hide: true,
//     open: false,
//   },
//   header: {
//     hide: false, //if true then header will not render at all
//     fixed: true, //if true then header will remain fixed on scroll
//   },
//   footer: {
//     hide: false, //if true then footer will not render at all
//   },
// };
// export default layoutConfig;

import {
  SIDEBAR_ANCHOR_POSITIONS,
  SIDEBAR_SCROLL_TYPES,
  SIDEBAR_STYLES,
  SIDEBAR_VARIANTS,
  SIDEBAR_VIEWS,
} from "@jumbo/utils/constants/layout";

const layoutConfig = {
  sidebar: {
    hide: true,
    open: false,
  },
  content: {
    sx: {
      p: 0,
      px: { lg: 0, xs: 0 },
    },
  },
  header: {
    hide: false,
  },
  footer: {
    hide: false,
  },
};

export default layoutConfig;
