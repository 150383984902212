import React from "react";
import "./animationStyle.css";

function Animation() {
  return (
    <div class="loading-container">
      <div class="double">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
    </div>
  );
}

export default Animation;
