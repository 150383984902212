import { getAssetPath } from "../../utils/appHelpers";
import { ASSET_AVATARS } from "../../utils/constants/paths";

export const teamData = [
  {
    id: 1,
    name: "Amit Jain",
    typeOfLawyer: "Lawyer Type 1",
    ongoingCases: "28",
    practiceCourt: "High Court",
    NextHearing: "10-Nov-2019",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar4.jpg`, "40x40"),
  },
  {
    id: 2,
    name: "Keshav Sharma",
    typeOfLawyer: "Lawyer Type 2",
    ongoingCases: "15",
    practiceCourt: "High Court",
    NextHearing: "10-Dec-2022",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar7.jpg`, "40x40"),
  },
  {
    id: 3,
    name: "Sanjay Singh",
    typeOfLawyer: "Lawyer Type 1",
    ongoingCases: "20",
    practiceCourt: "Supreme Court",
    NextHearing: "14-Feb-2023",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar7.jpg`, "40x40"),
  },
  {
    id: 4,
    name: "Soniya Singh",
    typeOfLawyer: "Lawyer Type 1",
    ongoingCases: "20",
    practiceCourt: "Supreme Court",
    NextHearing: "14-Feb-2023",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar6.jpg`, "40x40"),
  },
  {
    id: 5,
    name: "Pragya Singh",
    typeOfLawyer: "Lawyer Type 1",
    ongoingCases: "20",
    practiceCourt: "High Court",
    NextHearing: "18-Feb-2023",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar9.jpg`, "40x40"),
  },
  {
    id: 5,
    name: "Rishabh Thakur",
    typeOfLawyer: "Lawyer Type 4",
    ongoingCases: "20",
    practiceCourt: "High Court",
    NextHearing: "18-Feb-2023",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar4.jpg`, "40x40"),
  },
];
