import React, { useEffect, useState } from "react";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import { Typography } from "@mui/material";

import { Autocomplete, Checkbox, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Div from "@jumbo/shared/Div";
import { Screen3Container, Screen3InputSection } from "./style";
import { Link, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { loginValidations, loginValidations2 } from "app/utils/validators";
import { lang } from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  UserBarCouncil,
  UserLawyerEducation,
  UserLawyerLanguage,
  UserLawyerSpecialisation,
  UserPracticeCourts,
  UserPracticeStates,
} from "app/redux/actions/UpdatedInputs";
import { LawyerProfileUpdate } from "app/Apis/User";
const Screen3 = ({
  activeStep,
  progressIncrement,
  progressDecrement,
  screenState,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state) => state?.onboardingInputs?.accessToken
  );

  const barCouncilRegistrationNo = useSelector(
    (state) => state?.updateInputs?.barCouncilRegistrationNo
  );

  const practiceStates = useSelector(
    (state) => state?.updateInputs?.practiceStates
  );

  const practiceCourts = useSelector(
    (state) => state?.updateInputs?.practiceCourts
  );

  const lawyerEducations = useSelector(
    (state) => state?.updateInputs?.lawyerEducations
  );

  const lawyerSpecialisation = useSelector(
    (state) => state?.updateInputs?.lawyerSpecialisation
  );

  const lawyerLanguage = useSelector(
    (state) => state?.updateInputs?.lawyerLanguage
  );

  const formik = useFormik({
    initialValues: {
      barcouncilnumber: barCouncilRegistrationNo,
    },
    validationSchema: loginValidations2,
    enableReinitialize: true,
    onSubmit: (values) => {
      Incrementfn();
      // navigate("/onboarding");
    },
  });

  const State = ["Delhi", "Jharkhand"];
  const PracticeCourt = [
    "Court 1",
    "Court 2",
    "Court 3",

    // "Court 1",
    // "Court 2",
    // "Court 3",
  ];
  const Education = [
    "12th",
    "B-Tech",
    "B-COM",
    "BSC",
    // "12th",
    // "B-Tech",
    // "B-COM",
    // "BSC",
  ];

  const Specialisation = [
    "Developer",
    "Sportsman",
    "Guitarist",
    "Pianist",

    // "Developer",
    // "Sportsman",
    // "Guitarist",
    // "Pianist",
  ];

  const Language = [
    "Hindi",
    "English",
    "Spanish",
    "French",
    // "Hindi",
    // "English",
    // "Spanish",
    // "French",
  ];

  const [fieldState, setFieldState] = useState(
    practiceStates?.length > 0 ? practiceStates : []
  );
  const [fieldPracticeCourt, setFieldPracticeCourt] = useState(
    practiceCourts?.length > 0 ? practiceCourts : []
  );
  const [fieldEducation, setFieldEducation] = useState(
    lawyerEducations?.length > 0 ? lawyerEducations : []
  );
  const [fieldSpecialisation, setFieldSpecialisation] = useState(
    lawyerSpecialisation?.length > 0 ? lawyerSpecialisation : []
  );
  const [fieldLanguage, setFieldLanguage] = useState(
    lawyerLanguage?.length > 0 ? lawyerLanguage : []
  );

  const [error, setError] = useState(false);

  //error
  const [stateError, setStateError] = useState(false);
  const [practiceCourtError, setPracticeCourtError] = useState(false);
  const [specialisationError, setSpecialisationError] = useState(false);
  const [languageError, setLanguageError] = useState(false);
  const [educationError, setEducationError] = useState(false);
  // console.log(fieldState);
  // console.log(fieldPracticeCourt);

  const Incrementfn = async () => {
    if (fieldState.length === 0) {
      setError(true);
      setStateError(true);
    }
    if (fieldPracticeCourt.length === 0) {
      setError(true);
      setPracticeCourtError(true);
    }
    if (fieldEducation.length === 0) {
      setError(true);
      setEducationError(true);
    }
    if (fieldSpecialisation.length === 0) {
      setError(true);
      setSpecialisationError(true);
    }
    if (fieldLanguage.length === 0) {
      setError(true);
      setLanguageError(true);
    } else {
      setError(false);
      setStateError(false);
      setPracticeCourtError(false);
      setEducationError(false);
      setSpecialisationError(false);
      setLanguageError(false);

      var obj = {
        barCouncilRegistrationNo,
        practiceStates,
        practiceCourts,
        lawyerEducations,
        lawyerSpecialisation,
        lawyerLanguage,
      };

      const response = await LawyerProfileUpdate(accessToken, obj);

      if (response) {
        console.log("lawyer data is ", response);
        navigate("/onboardingsuccess");
      } else {
        console.log("something went wrong!");
      }

      // navigate("/onboardingsuccess");
    }
  };

  const ValuesToReduxForState = () => {
    setStateError(false);
    dispatch(UserPracticeStates(fieldState));
  };

  const ValuesToReduxForCourt = () => {
    setStateError(false);
    dispatch(UserPracticeCourts(fieldPracticeCourt));
  };

  const ValuesToReduxForEducation = () => {
    setStateError(false);
    dispatch(UserLawyerEducation(fieldEducation));
  };

  const ValuesToReduxForSpecialisation = () => {
    setStateError(false);
    dispatch(UserLawyerSpecialisation(fieldSpecialisation));
  };

  const valuesToReduxForLanguage = () => {
    setStateError(false);
    dispatch(UserLawyerLanguage(fieldLanguage));
  };

  return (
    <Screen3Container>
      <Screen3InputSection>
        <Typography
          style={{
            marginBottom: "30px",
            fontWeight: "600",
            marginTop: "35px",
            fontSize: "20px",
            textAlign: "left",
          }}
        >
          Lets learn about your profession
        </Typography>

        {/* state */}

        <Div>
          <TextField
            // style={{ width: "30vw" }}
            style={{ width: "100%" }}
            id="outlined-multiline-flexible"
            label="Bar Council Number"
            required
            color="secondary"
            inputProps={{ maxLength: 15 }}
            name="barcouncilnumber"
            maxRows={4}
            error={formik.errors.barcouncilnumber}
            value={formik.values.barcouncilnumber}
            onChange={formik.handleChange}
            helperText={formik.errors.barcouncilnumber}
            onBlur={dispatch(UserBarCouncil(formik.values.barcouncilnumber))}
          />
        </Div>

        <Div>
          <Autocomplete
            style={{ width: "100%" }}
            value={fieldState}
            onChange={(event, value) => setFieldState(value)}
            multiple
            options={State}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            sx={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                sx={{ marginTop: "10px" }}
                {...params}
                label="Select State"
                placeholder="State"
                color="secondary"
                error={stateError}
                onBlur={() =>
                  fieldState.length > 0
                    ? ValuesToReduxForState()
                    : setStateError(true)
                }
              />
            )}
          />
          {stateError && (
            <Typography sx={{ fontSize: "10px", color: "#E73145" }}>
              Please Select atleast one value!
            </Typography>
          )}
        </Div>

        {/* practice court */}
        <Div sx={{ mt: 0 }}>
          <Autocomplete
            style={{ width: "100%" }}
            value={fieldPracticeCourt}
            onChange={(event, value) => setFieldPracticeCourt(value)}
            multiple
            options={PracticeCourt}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            sx={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                sx={{ marginTop: "10px" }}
                {...params}
                label="Select Practice Court"
                placeholder="Practice Court"
                color="secondary"
                required
                error={practiceCourtError}
                onBlur={() =>
                  fieldPracticeCourt.length > 0
                    ? ValuesToReduxForCourt()
                    : setPracticeCourtError(true)
                }
              />
            )}
          />
          {practiceCourtError && (
            <Typography sx={{ fontSize: "10px", color: "#E73145" }}>
              Please Select atleast one value!
            </Typography>
          )}
        </Div>

        {/* Education */}
        <Div>
          <Autocomplete
            style={{ width: "100%" }}
            value={fieldEducation}
            onChange={(event, value) => setFieldEducation(value)}
            multiple
            // id="checkboxes-tags-demo"
            options={Education}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            sx={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                sx={{ marginTop: "10px" }}
                {...params}
                error={educationError}
                label="Select Education"
                placeholder="Education"
                required
                color="secondary"
                onBlur={() =>
                  fieldEducation.length > 0
                    ? ValuesToReduxForEducation()
                    : setEducationError(true)
                }
              />
            )}
          />
          {educationError && (
            <Typography sx={{ fontSize: "10px", color: "#E73145" }}>
              Please Select atleast one value!
            </Typography>
          )}
        </Div>

        {/* specialisation */}
        <Div>
          <Autocomplete
            style={{ width: "100%" }}
            value={fieldSpecialisation}
            onChange={(event, value) => setFieldSpecialisation(value)}
            multiple
            // id="checkboxes-tags-demo"
            options={Specialisation}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            sx={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                sx={{ marginTop: "10px" }}
                {...params}
                label="Specialisation"
                placeholder="Specialisation"
                required
                color="secondary"
                error={specialisationError}
                onBlur={() =>
                  fieldSpecialisation.length > 0
                    ? ValuesToReduxForSpecialisation()
                    : setSpecialisationError(true)
                }
              />
            )}
          />
          {specialisationError && (
            <Typography sx={{ fontSize: "10px", color: "#E73145" }}>
              Please Select atleast one value!
            </Typography>
          )}
        </Div>

        {/* Language */}
        <Div>
          <Autocomplete
            style={{ width: "100%" }}
            multiple
            value={fieldLanguage}
            onChange={(event, value) => setFieldLanguage(value)}
            // id="checkboxes-tags-demo"
            options={Language}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            sx={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                sx={{ marginTop: "10px" }}
                {...params}
                label="Language"
                placeholder="Select Language"
                required
                color="secondary"
                error={languageError}
                onBlur={() =>
                  fieldLanguage.length > 0
                    ? valuesToReduxForLanguage()
                    : setLanguageError(true)
                }
              />
            )}
          />
          {languageError && (
            <Typography sx={{ fontSize: "10px", color: "#E73145" }}>
              Please Select atleast one value!
            </Typography>
          )}
        </Div>
        <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 5,
          }}
        >
          <LoadingButton
            // onClick={handleNext}
            disabled={activeStep !== 1 ? false : true}
            onClick={progressDecrement}
            style={{
              backgroundColor: activeStep !== 1 ? "black" : "",
            }}
            variant="contained"
            sx={{ width: 100, padding: 1 }}
          >
            Back
          </LoadingButton>

          <LoadingButton
            // onClick={handleNext}
            onClick={formik.handleSubmit}
            style={{
              backgroundColor: "black",
            }}
            variant="contained"
            sx={{ width: 100, padding: 1 }}
          >
            Continue
          </LoadingButton>
        </Div>
      </Screen3InputSection>
    </Screen3Container>
  );
};

export default Screen3;
