import React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Avatar from "@mui/material/Avatar";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { alpha, Paper, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import Div from "@jumbo/shared/Div";
import { CaseData } from "./CaseData";
import ActionsMenu from "./ActionsMenu";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CaseCard = ({ item }) => {
  //const [expanded, setExpanded] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    if (CaseData.find((Case) => Case.id === Case.id)) setExpanded(!expanded);
  };

  return (
    <Paper
      elevation={0}
      variant="outlined"
      square
      sx={{
        mt: 1,
        "&:hover": {
          boxShadow: `0 3px 10px 0 ${alpha("#000", 0.2)}`,
          transform: "translateY(-4px)",
          borderBottomColor: "transparent",
        },
      }}
    >
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          px: 1,
          flexDirection: "row-reverse",

          "& .MuiAccordionSummary-content": {
            alignItems: "center",

            "&.Mui-expanded": {
              margin: "12px 0",
            },
          },
          ".MuiAccordionSummary-expandIconWrapper": {
            borderRadius: 1,
            border: 1,
            color: "text.secondary",
            borderColor: "divider",
            transform: "none",
            height: 28,
            width: 28,
            alignItems: "center",
            justifyContent: "center",
            mr: 1,

            "&.Mui-expanded": {
              transform: "none",
              color: "primary.main",
              borderColor: "primary.main",
            },

            "& svg": {
              fontSize: "1.25rem",
            },
          },
        }}
      >
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          {expanded ? <RemoveIcon /> : <AddIcon />}
        </ExpandMore>
        <Div
          sx={{
            width: { xs: "auto", lg: "20%" },
            flexShrink: 0,
            px: 1,
            flex: { xs: "1", lg: "0 1 auto" },
          }}
        >
          <Typography mb={0.5} fontSize={18} fontWeight="700" color="primary">
            {item.title}
          </Typography>
          <Typography
            variant={"h3"}
            color={"text.secondary"}
            fontSize={14}
            fontWeight="700"
          >
            {item.sectionType}
          </Typography>
        </Div>
        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "10%",
            flexShrink: 0,
            px: 2,
          }}
        >
          <Chip
            label={`${item.status}`}
            color="chipColor"
            size="small"
            sx={{ fontSize: 12, fontWeight: "500" }}
          />
        </Div>

        <Div
          sx={{
            display: { xs: "none", lg: "flex" },
            width: "40%",
            flexShrink: 0,
            px: 2,
            gap: 5,
          }}
        >
          <Div>
            <Typography
              fontSize={"16px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
            >
              Client
            </Typography>
            <Typography fontSize={"14px"}>{item.client}</Typography>
          </Div>
          <Div>
            <Typography
              fontSize={"16px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
            >
              Lawyer
            </Typography>
            <Typography fontSize={"14px"}>{item.lawyer}</Typography>
          </Div>
          <Div>
            <Typography
              fontSize={"16px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
            >
              Court
            </Typography>
            <Div sx={{ display: "flex", gap: 1 }}>
              <AccountBalanceIcon color="secondary" />
              <Typography fontSize={"14px"}>{item.court}</Typography>
            </Div>
          </Div>
        </Div>
        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "15%",
            flexShrink: 0,
            px: 1,
          }}
        >
          <Typography
            fontSize={"16px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
          >
            Starting Hearing
          </Typography>
          <Div sx={{ display: "flex", gap: 1 }}>
            <CalendarMonthIcon color="secondary" />
            <Typography fontSize={"14px"}>{item.startingDate}</Typography>
          </Div>
        </Div>
        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "15%",
            flexShrink: 0,
            px: 1,
          }}
        >
          <Typography
            fontSize={"16px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
          >
            Next Hearing
          </Typography>
          <Div sx={{ display: "flex", gap: 1 }}>
            <CalendarMonthIcon color="secondary" />
            <Typography fontSize={"14px"}>{item.NextHearing}</Typography>
          </Div>
        </Div>
        <Div>
          <ActionsMenu />
        </Div>
      </AccordionSummary>

      <Collapse
        in={expanded}
        timeout="auto"
        sx={{
          borderTop: 1,
          borderColor: "divider",
          p: (theme) => theme.spacing(2, 2, 2, 2),
        }}
        unmountOnExit
      >
        <Typography variant={"h5"}>Details</Typography>
        <Div
          sx={{
            display: { xs: "flex", lg: "none" },
            minWidth: 0,
            flexDirection: "column",
          }}
        >
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              alignItems: "center",
              justifyContent: "space-between",
              mt: 1,
              mb: 2,
              flexWrap: "wrap",
            }}
          >
            <Div>
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Client
              </Typography>
              <Typography variant={"body1"}>{item.client}</Typography>
            </Div>
            <Div>
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Lawyer
              </Typography>
              <Typography variant={"body1"}>{item.lawyer}</Typography>
            </Div>
            <Div>
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Court
              </Typography>
              <Typography variant={"body1"}>{item.court}</Typography>
            </Div>
          </Div>
          <Div sx={{ mb: 2, display: { xs: "inline-block", lg: "none" } }}>
            <Typography
              fontSize={"12px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
            >
              Status
            </Typography>
            <Chip
              label={`${item.status}`}
              color="chipColor"
              size="small"
              sx={{ fontSize: 12, fontWeight: "500" }}
            />
          </Div>

          <Div sx={{ mb: 3, maxWidth: 280 }}>
            <Div
              sx={{
                display: { xs: "flex", lg: "none" },
                flexDirection: { xs: "column", lg: "row" },
                gap: 3,
              }}
            >
              <Div>
                <Typography
                  fontSize={"16px"}
                  variant={"h6"}
                  color={"text.secondary"}
                  mb={0.25}
                >
                  Starting Hearing
                </Typography>
                <Div sx={{ display: "flex", gap: 1 }}>
                  <CalendarMonthIcon color="secondary" />
                  <Typography fontSize={"14px"}>{item.startingDate}</Typography>
                </Div>
              </Div>
              <Div>
                <Typography
                  fontSize={"16px"}
                  variant={"h6"}
                  color={"text.secondary"}
                  mb={0.25}
                >
                  Next Hearing
                </Typography>
                <Div sx={{ display: "flex", gap: 1 }}>
                  <CalendarMonthIcon color="secondary" />
                  <Typography fontSize={"14px"}>{item.NextHearing}</Typography>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>

        <Typography variant={"body1"} color={"text.secondary"}>
          {item.name}
        </Typography>
      </Collapse>
    </Paper>
  );
};
/* Todo item prop define */
export default CaseCard;
