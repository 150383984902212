import styled from "styled-components";

export const NoCaseContainer = styled.div`
  /* margin-left: 10%;
  margin-right: 10%; */
  /* background-color: green; */
  height: 100%;
`;

export const CaseContainer = styled.div`
  margin-left: 25%;
  margin-right: 25%;
  /* background-color: green; */
  height: 100%;
`;
