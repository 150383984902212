import React from "react";
import ProjectItem from "./ProjectItem";
import { projects } from "./data";

const ProjectsList = () => {
  return (
    <React.Fragment>
      {projects.map((project, index) => (
        <ProjectItem Key={index} item={project} />
      ))}
    </React.Fragment>
  );
};

export default ProjectsList;
