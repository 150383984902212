import styled from "styled-components";

export const OnboardingContainer = styled.div`
  /* background-color: green; */
  /* height: 100vh; */
  margin-left: 30%;
  margin-right: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  /* position: relative; */
`;

export const CardContainer = styled.div`
  display: flex;

  justify-content: space-between;
`;

export const Screen1Container = styled.div`
  width: 100%;
`;

export const Screen2Container = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 20px;
  }
`;

export const UploadSection = styled.div`
  display: flex;

  align-items: center;
  width: 100%;

  justify-content: space-between;
`;

export const Screen3Container = styled.div`
  width: 100%;
`;

export const Screen3InputSection = styled.div`
  width: 100%;
  & > * {
    margin-bottom: 20px;
  }
`;

export const Screen4Container = styled.div`
  background-color: yellow;
  height: 100%;
`;
