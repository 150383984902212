// import React, { useEffect, useState } from "react";
// import { TextField } from "@mui/material";
// import { Typography } from "@mui/material";
// import { Box } from "@mui/system";
// import UploadFileIcon from "@mui/icons-material/UploadFile";
// import CloseIcon from "@mui/icons-material/Close";
// import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

// import JumboDemoCard from "@jumbo/components/JumboDemoCard";
// import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Div from "@jumbo/shared/Div";
// import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
// import JumboCardQuick from "@jumbo/components/JumboCardQuick";
// import { useFormik } from "formik";
// import { ClientValidations } from "app/utils/validators";
// function EachClient({
//   count,
//   clientNo,
//   setClientNo,
//   name,
//   setError,
//   formik,
//   email,
//   setEmail,
//   fullname,
//   setFullName,
//   num,
//   setNum,
//   pin,
//   setPin,
//   house,
//   setHosue,
//   city,
//   setCity,
//   state,
//   setState,
//   // formik,
// }) {
//   const [documentUploaded, setDocumentUploaded] = useState();

//   const [fileUploaded, setFileUploaded] = useState(false);
//   const hiddenFileInput = React.useRef(null);

//   const handleInputChange = (event) => {
//     const fileUploaded = event.target.files[0];
//     console.log(fileUploaded);

//     setDocumentUploaded(URL.createObjectURL(event.target.files[0]));
//   };

//   const handleClick = (event) => {
//     hiddenFileInput.current.click();
//   };

//   console.log(documentUploaded);

//   const removeDocument = () => {
//     // const newDocumentUploaded = documentUploaded?.filter((document) => {
//     //   return document !== deletedUrl;
//     // });

//     setDocumentUploaded(null);
//     setFileUploaded(false);
//   };
//   const [expanded, setExpanded] = React.useState(count === 0 ? true : false);
//   const handleChange = (panel) => (event, isExpanded) => {
//     setExpanded(isExpanded ? panel : false);
//   };

//   const handleNumberChange = (e) => {
//     const regex = /^[0-9\b]+$/;
//     if (e.target.value === "" || regex.test(e.target.value)) {
//       setNum(e.target.value);
//     }
//   };

//   const handlePinChange = (e) => {
//     const regex = /^[0-9\b]+$/;
//     if (e.target.value === "" || regex.test(e.target.value)) {
//       setPin(e.target.value);
//     }
//   };
//   return (
//     <Accordion
//       elevation={0}
//       sx={{
//         marginBottom: 1,
//         width: "100%",
//         border: "none",
//       }}
//       expanded={expanded === true}
//       onChange={handleChange(true)}
//     >
//       <AccordionSummary
//         sx={{ height: "50px" }}
//         expandIcon={<ExpandMoreIcon />}
//         id="panel1bh-header"
//       >
//         <Typography
//           variant="h6"
//           sx={{
//             width: "90%",
//             flexShrink: 0,
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//           }}
//         >
//           {name} {count + 1}
//           {count !== 0 && (
//             <p onClick={() => setClientNo(clientNo - 1)}>
//               <DeleteOutlineIcon sx={{ fontSize: "24px" }} />
//             </p>
//           )}
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails>
//         <div
//           style={{
//             height: "100%",
//             width: "100%",
//           }}
//         >
//           {/* <CaseContainer> */}
//           <Div
//             sx={{
//               height: "100%",
//               width: "100%",
//             }}
//           >
//             {/* first name */}
//             <TextField
//               sx={{ width: "100%" }}
//               id="outlined-basic"
//               label="Full Name*"
//               variant="outlined"
//               color="secondary"
//               // onBlur={formik.handleSubmit}
//               onChange={(e) => setFullName(e.target.value)}
//               value={formik.values.fullname}
//               error={formik.errors.fullname}
//               name="fullname"
//               helperText={formik.errors.fullname}
//             />
//             <TextField
//               sx={{ mb: 0, mt: 2, width: "100%" }}
//               // type="number"
//               id="outlined-basic"
//               label="Enter Mobile Number*"
//               variant="outlined"
//               color="secondary"
//               onChange={(e) => handleNumberChange(e)}
//               inputProps={{ maxLength: 10, minLength: 10 }}
//               //  onBlur={formik.handleSubmit}
//               value={formik.values.num}
//               error={formik.errors.num}
//               name="num"
//               helperText={formik.errors.num}
//             />
//             {/* email */}
//             <TextField
//               sx={{ mb: 0, mt: 2, width: "100%" }}
//               id="outlined-basic"
//               label="Email"
//               variant="outlined"
//               color="secondary"
//               //   onBlur={formik.handleSubmit}
//               onChange={(e) => setEmail(e.target.value)}
//               value={formik.values.email}
//               error={formik.errors.email}
//               name="email"
//               helperText={formik.errors.email}
//             />
//             {/* add addresses */}
//             <Typography variant="h6" sx={{ mb: 0, mt: 2 }}>
//               Add Addresses
//             </Typography>
//             {/* house flat */}{" "}
//             <TextField
//               sx={{ mb: 0, mt: 2, width: "100%" }}
//               id="outlined-basic"
//               label="House/Flat/Office"
//               variant="outlined"
//               onChange={(e) => setHosue(e.target.value)}
//               value={formik.values.house}
//               error={formik.errors.house}
//               name="house"
//               color="secondary"
//               helperText={formik.errors.house}
//             />
//             {/* pincode */}
//             <TextField
//               sx={{ mb: 0, mt: 2, width: "100%" }}
//               // type="number"
//               id="outlined-basic"
//               label="Pin Code"
//               variant="outlined"
//               color="secondary"
//               onChange={(e) => handlePinChange(e)}
//               inputProps={{ maxLength: 6, minLength: 6 }}
//               value={formik.values.pin}
//               error={formik.errors.pin}
//               name="pin"
//               helperText={formik.errors.pin}
//             />
//             {/* city */}
//             <TextField
//               sx={{ mb: 0, mt: 2, width: "100%" }}
//               id="outlined-basic"
//               label="City"
//               variant="outlined"
//               color="secondary"
//               onChange={(e) => setCity(e.target.value)}
//               value={formik.values.city}
//               error={formik.errors.city}
//               name="city"
//               helperText={formik.errors.city}
//             />
//             {/* state*/}
//             <TextField
//               sx={{ mb: 0, mt: 2, width: "100%" }}
//               id="outlined-basic"
//               label="State"
//               variant="outlined"
//               color="secondary"
//               onChange={(e) => setState(e.target.value)}
//               value={formik.values.state}
//               error={formik.errors.state}
//               name="state"
//               helperText={formik.errors.state}
//               //    onBlur={formik.handleSubmit}
//             />
//             {/* Add Client Photo */}
//             <Typography variant="h6" sx={{ mb: 0, mt: 2 }}>
//               Add Client Photo
//             </Typography>
//             {/* upload */}
//             {/* <Div sx={{ mt: "20px", width: "100%" }}>

//               </Div> */}
//             <Box
//               sx={{
//                 mb: 0,
//                 mt: 2,
//                 width: "100%",
//                 height: "50.71px",

//                 border: "1px solid #cdced1",
//                 borderRadius: "3px",

//                 display: "flex",
//                 alignItems: "center",
//               }}
//             >
//               <Div sx={{ display: "flex", alignItems: "center" }}>
//                 <AddPhotoAlternateIcon
//                   sx={{ fontSize: "40px" }}
//                   onClick={handleClick}
//                 />
//                 <Typography sx={{ fontSize: "14px", color: "#929faf" }}>
//                   Attach Photo
//                 </Typography>
//               </Div>
//             </Box>
//             <input
//               style={{ mb: 0, mt: 2, width: "30vw" }}
//               accept="image/*"
//               multiple
//               type="file"
//               ref={hiddenFileInput}
//               onChange={handleInputChange}
//               hidden
//             />
//             <Typography
//               sx={{ color: "#929faf", fontSize: "12px", mb: 0, mt: "2px" }}
//             >
//               File Should be jpg, jpeg, png, Pdf
//             </Typography>
//             {/* uploaded image */}
//             <Div
//               sx={{
//                 display: "flex",
//                 alignItems: "center",
//                 overflowX: "scroll",
//                 width: "100%",
//               }}
//             >
//               {documentUploaded && (
//                 <Div
//                   sx={{
//                     position: "relative",
//                     // backgroundColor: "green",
//                     mr: 2,
//                   }}
//                 >
//                   <img
//                     style={{
//                       width: { md: "5vw" },
//                       height: "10vh",
//                       borderRadius: "50%",
//                       objectFit: "contain",
//                       marginRight: "10px",
//                     }}
//                     src={documentUploaded}
//                     alt=""
//                   />
//                   <CloseIcon
//                     onClick={removeDocument}
//                     sx={{ position: "absolute", right: 2 }}
//                   />
//                 </Div>
//               )}
//             </Div>
//             {documentUploaded && (
//               <Typography sx={{ color: "green", fontSize: "16px" }}>
//                 Completed!
//               </Typography>
//             )}
//             <Div
//               sx={{
//                 mt: 5,
//                 display: "flex",
//                 justifyContent: "flex-end",
//                 // alignItems: "center",
//               }}
//             >
//               {count !== 0 && (
//                 <Div
//                   onClick={() => setClientNo(clientNo - 1)}
//                   sx={{
//                     display: "flex",
//                     alignItems: "center",
//                     cursor: "pointer",
//                   }}
//                 >
//                   <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
//                     Delete Client
//                   </Typography>
//                   <DeleteOutlineIcon sx={{ fontSize: "26px" }} />
//                 </Div>
//               )}
//             </Div>
//           </Div>

//           {/* </CaseContainer> */}
//         </div>
//       </AccordionDetails>
//     </Accordion>
//     //   </Div>
//     // </div>
//   );
// }

// export default EachClient;

import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Div from "@jumbo/shared/Div";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useFormik } from "formik";
import { ClientValidations } from "app/utils/validators";
import { add } from "date-fns";
function EachClient({
  count,
  clientNo,
  setClientNo,
  name,
  // formik,
  // num,
  // setNum,
  // pin,
  // setPin,
  // setFullName,
  // setEmail,
  // setHosue,
  // setCity,
  // setState,
  handleNextButton,
  checkSubmit,
  setCheckSubmit,
  setError,
  addClientClick,
}) {
  const [documentUploaded, setDocumentUploaded] = useState();

  const [fileUploaded, setFileUploaded] = useState(false);
  const hiddenFileInput = React.useRef(null);

  const handleInputChange = (event) => {
    const fileUploaded = event.target.files[0];
    console.log(fileUploaded);

    setDocumentUploaded(URL.createObjectURL(event.target.files[0]));
  };

  const [num, setNum] = React.useState();
  const [pin, setPin] = React.useState();

  const [fullname, setFullName] = useState();
  const [email, setEmail] = useState();
  const [house, setHosue] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();

  const formik = useFormik({
    initialValues: {
      fullname: fullname,
      email: email,
      house: house,
      city: city,
      state: state,
      num: num,
      pin: pin,
    },
    // innerRef: { formRef },
    validationSchema: ClientValidations,
    enableReinitialize: true,
    onSubmit: (values, text) => {
      //  console.log(text);
      // setError(false);
      if (count !== 0) {
        if (addClientClick === count) {
          console.log(addClientClick, count);
        } //handleNextButton();
        else {
          console.log(addClientClick, count);
        }
      } else {
        handleNextButton();
      }
    },
  });
  console.log(clientNo, count);

  useEffect(() => {
    if (checkSubmit === true) {
      formik.handleSubmit();
      setCheckSubmit(false);
    }
  }, [checkSubmit]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  // console.log(documentUploaded);

  const removeDocument = () => {
    // const newDocumentUploaded = documentUploaded?.filter((document) => {
    //   return document !== deletedUrl;
    // });

    setDocumentUploaded(null);
    setFileUploaded(false);
  };
  const [expanded, setExpanded] = React.useState(count === 0 ? true : false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleNumberChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setNum(e.target.value);
    }
  };

  const handlePinChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setPin(e.target.value);
    }
  };

  return (
    <Accordion
      elevation={0}
      sx={{
        marginBottom: 1,
        width: "100%",
        border: "none",
      }}
      expanded={expanded === true}
      onChange={handleChange(true)}
    >
      <AccordionSummary
        sx={{ height: "50px" }}
        expandIcon={<ExpandMoreIcon />}
        id="panel1bh-header"
      >
        <Typography
          variant="h6"
          sx={{
            width: "90%",
            flexShrink: 0,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {name} {count + 1}
          {count !== 0 && (
            <p onClick={() => setClientNo(clientNo - 1)}>
              <DeleteOutlineIcon sx={{ fontSize: "24px" }} />
            </p>
          )}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          {/* <CaseContainer> */}
          <Div
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            {/* first name */}
            <TextField
              sx={{ width: "100%" }}
              id="outlined-basic"
              label="Full Name*"
              variant="outlined"
              value={formik.values.fullname}
              onChange={(e) => setFullName(e.target.value)}
              error={formik.errors.fullname}
              name="fullname"
              color="secondary"
              helperText={formik.errors.fullname}
            />
            <TextField
              sx={{ mb: 0, mt: 2, width: "100%" }}
              // type="number"
              id="outlined-basic"
              label="Enter Mobile Number*"
              variant="outlined"
              onChange={(e) => handleNumberChange(e)}
              value={formik.values.num}
              error={formik.errors.num}
              name="num"
              color="secondary"
              helperText={formik.errors.num}
              inputProps={{ maxLength: 10, minLength: 10 }}
            />
            {/* email */}
            <TextField
              sx={{ mb: 0, mt: 2, width: "100%" }}
              id="outlined-basic"
              label="Email"
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
              value={formik.values.email}
              error={formik.errors.email}
              name="email"
              color="secondary"
              helperText={formik.errors.email}
            />
            {/* add addresses */}
            <Typography variant="h6" sx={{ mb: 0, mt: 2 }}>
              Add Addresses
            </Typography>
            {/* house flat */}{" "}
            <TextField
              sx={{ mb: 0, mt: 2, width: "100%" }}
              id="outlined-basic"
              label="House/Flat/Office"
              variant="outlined"
              onChange={(e) => setHosue(e.target.value)}
              value={formik.values.house}
              error={formik.errors.house}
              name="house"
              color="secondary"
              helperText={formik.errors.house}
            />
            {/* pincode */}
            <TextField
              sx={{ mb: 0, mt: 2, width: "100%" }}
              // type="number"
              id="outlined-basic"
              label="Pin Code"
              variant="outlined"
              onChange={(e) => handlePinChange(e)}
              value={formik.values.pin}
              error={formik.errors.pin}
              name="pin"
              color="secondary"
              helperText={formik.errors.pin}
              inputProps={{ maxLength: 6, minLength: 6 }}
            />
            {/* city */}
            <TextField
              sx={{ mb: 0, mt: 2, width: "100%" }}
              id="outlined-basic"
              label="City"
              variant="outlined"
              onChange={(e) => setCity(e.target.value)}
              value={formik.values.city}
              error={formik.errors.city}
              name="city"
              color="secondary"
              helperText={formik.errors.city}
            />
            {/* state*/}
            <TextField
              sx={{ mb: 0, mt: 2, width: "100%" }}
              id="outlined-basic"
              label="State"
              variant="outlined"
              onChange={(e) => setState(e.target.value)}
              value={formik.values.state}
              error={formik.errors.state}
              name="state"
              color="secondary"
              helperText={formik.errors.state}
              onBlur={() => console.log(count, Array(count).fill(count).length)}
            />
            {/* Add Client Photo */}
            <Typography variant="h6" sx={{ mb: 0, mt: 2 }}>
              Add Client Photo
            </Typography>
            {/* upload */}
            {/* <Div sx={{ mt: "20px", width: "100%" }}>

              </Div> */}
            <Box
              sx={{
                mb: 0,
                mt: 2,
                width: "100%",
                height: "50.71px",

                border: "1px solid #cdced1",
                borderRadius: "3px",

                display: "flex",
                alignItems: "center",
              }}
            >
              <Div sx={{ display: "flex", alignItems: "center" }}>
                <AddPhotoAlternateIcon
                  sx={{ fontSize: "40px" }}
                  onClick={handleClick}
                />
                <Typography sx={{ fontSize: "14px", color: "#929faf" }}>
                  Attach Photo
                </Typography>
              </Div>
            </Box>
            <input
              style={{ mb: 0, mt: 2, width: "30vw" }}
              accept="image/*"
              multiple
              type="file"
              ref={hiddenFileInput}
              onChange={handleInputChange}
              hidden
            />
            <Typography
              sx={{ color: "#929faf", fontSize: "12px", mb: 0, mt: "2px" }}
            >
              File Should be jpg, jpeg, png, Pdf
            </Typography>
            {/* uploaded image */}
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                overflowX: "scroll",
                width: "100%",
              }}
            >
              {documentUploaded && (
                <Div
                  sx={{
                    position: "relative",
                    // backgroundColor: "green",
                    mr: 2,
                  }}
                >
                  <img
                    style={{
                      width: { md: "5vw" },
                      height: "10vh",
                      borderRadius: "50%",
                      objectFit: "contain",
                      marginRight: "10px",
                    }}
                    src={documentUploaded}
                    alt=""
                  />
                  <CloseIcon
                    onClick={removeDocument}
                    sx={{ position: "absolute", right: 2 }}
                  />
                </Div>
              )}
            </Div>
            {documentUploaded && (
              <Typography sx={{ color: "green", fontSize: "16px" }}>
                Completed!
              </Typography>
            )}
            <Div
              sx={{
                mt: 5,
                display: "flex",
                justifyContent: "flex-end",
                // alignItems: "center",
              }}
            >
              {count !== 0 && (
                <Div
                  onClick={() => setClientNo(clientNo - 1)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                    Delete Client
                  </Typography>
                  <DeleteOutlineIcon sx={{ fontSize: "26px" }} />
                </Div>
              )}
            </Div>
          </Div>

          {/* </CaseContainer> */}
        </div>
      </AccordionDetails>
    </Accordion>
    //   </Div>
    // </div>
  );
}

export default EachClient;
