import React, { useEffect, useState } from "react";

import { Alert, Button, Snackbar, Typography } from "@mui/material";

import Div from "@jumbo/shared/Div";
import EachClient from "./EachClient";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

function OppositeClient() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [count, setCount] = useState(1);

  const AddClient = () => {
    setCount((state) => state + 1);
  };
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleNavigate = () => {
    navigate("/lawyers/clients");
  };
  const handleContinue = () => {
    setOpenSnackBar(true);
    setTimeout(handleNavigate, 2000);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        paddingBottom: "30px",
      }}
    >
      {openSnackBar && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnackBar}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          sx={{ zIndex: 1000, mt: 10 }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%", pr: 3, pl: 3, borderRadius: "10px" }}
          >
            <Typography variant="h6">
              Opposite Client Added Successfully!
            </Typography>
          </Alert>
        </Snackbar>
      )}
      {/* <CaseContainer> */}
      <Div
        sx={{
          marginLeft: { xs: "0%", md: "18%" },
          marginRight: { xs: "0%", md: "18%" },

          height: "100%",
        }}
      >
        {Array(count)
          .fill(count)
          .map((x, idx) => (
            <EachClient
              count={idx}
              clientNo={count}
              setClientNo={setCount}
              name={"Opposite Client"}
            />
          ))}
        <Div
          sx={{
            display: "flex",
            pr: 2,
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={AddClient}
            sx={{ backgroundColor: "secondary.main" }}
            style={{
              margin: "0",
              // marginTop: "-10px",
              mr: 2,
            }}
            variant="contained"
          >
            Add New Client
          </Button>
        </Div>
        <Div
          sx={{
            width: "100%",
            pl: { sm: "0", lg: "11%" },
            pr: { sm: "0", lg: "11%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 5,
          }}
        >
          <LoadingButton
            color="secondary"
            style={{
              backgroundColor: "black",
            }}
            sx={{ width: 100, padding: 1 }}
            variant="contained"
            onClick={() => handleContinue()}
          >
            Continue
          </LoadingButton>
        </Div>
      </Div>
    </div>
  );
}

export default OppositeClient;
