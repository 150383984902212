import React from "react";
import { useTheme } from "@mui/material/styles";
import Div from "@jumbo/shared/Div";
import { Button, MobileStepper, Typography } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Carousel } from "react-responsive-carousel";
import Box from "@mui/material/Box";
import { ASSET_IMAGES } from "../../utils/constants/paths";

const images = [
  {
    label: "1000+ verified Lawyers",
    description: "Online Consultation with a lawyer Anytime Anywhere",
    imgPath: `${ASSET_IMAGES}/products/Group 1.png`,
  },
  {
    label: "All Documents safe here",
    description: "Documentation by Expert Professionals",
    imgPath: `${ASSET_IMAGES}/products/Group 2.png`,
  },
  {
    label: "All Documents safe here",
    description: "Documentation by Expert Professionals",
    imgPath: `${ASSET_IMAGES}/products/Group 1.png`,
  },
  {
    label: "1000+ verified Lawyers",
    description: "Online Consultation with a lawyer Anytime Anywhere",
    imgPath: `${ASSET_IMAGES}/products/Group 2.png`,
  },
];
const SwipeableTextMobileStepper = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    if (activeStep !== step) {
      setActiveStep({ activeStep: step });
    }
  };
  return (
    <Div sx={{ maxWidth: 600 }}>
      <Carousel
        showArrows={false}
        showIndicators={false}
        interval={2000}
        showStatus={false}
        onChange={handleStepChange}
        selectedItem={activeStep}
        showThumbs={false}
        swipeable={false}
      >
        {images.map((item, index) => (
          <div key={item.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: { sm: "200", lg: "300" },
                  display: "block",
                  maxWidth: 500,
                  overflow: "hidden",
                  width: "100%",
                }}
                src={item.imgPath}
                alt={item.label}
              />
            ) : null}
          </div>
        ))}
      </Carousel>

      <MobileStepper
        steps={maxSteps}
        position="static"
        sx={{ bgcolor: "transparent" }}
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
      <Div
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            color: "#151513",
            fontSize: "24px",
            fontWeight: "700",
            mt: 5,
          }}
        >
          {images[activeStep].label}
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            color: "#8F8F90",
            fontSize: "14px",
            fontWeight: "600",
            mt: 1,
            textAlign: "center",
          }}
        >
          {images[activeStep].description}
        </Typography>
      </Div>
    </Div>
  );
};

export default SwipeableTextMobileStepper;
