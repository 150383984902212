import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import TimePickerComponent from "../components/TimePickerComponent";
import { LoadingButton } from "@mui/lab";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useFormik } from "formik";
import { OfficialDetailsValidations } from "app/utils/validators";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";

let initial = {
  mon: true,
  tue: false,
  wed: false,
  thur: false,
  fri: false,
  sat: false,
  sun: false,
};

const OfficialDetails = ({ handleBackButton, handleNextButton }) => {
  const LawyerType = ["Type1", "Type2", "Type3", "Type4"];
  const PracticeCourt = ["Court1", "Court2", "Court3", "Court4"];
  const [checked, setChecked] = React.useState(true);
  const [initialState, setInitialState] = React.useState(initial);
  const handleDayChange = (day) => {
    setInitialState({
      ...initialState,
      [day]: !initialState[day],
    });
  };
  const [lawyer, setLawyer] = React.useState([]);
  const [court, setCourt] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [caseTitleError, setCaseTitleError] = useState(false);
  const [value, setValue] = React.useState(dayjs("2014-08-18T21:11:54"));

  const [value2, setValue2] = React.useState(dayjs("2014-08-18T21:11:54"));

  const formik = useFormik({
    initialValues: {
      lawyer: lawyer,
      court: court,
      value: value,
      value2: value2,
    },
    validationSchema: OfficialDetailsValidations,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values, "FORMIK");
      // handleNextButton();
      handleNextButton();
    },
  });
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleChangeTime1 = (newValue) => {
    setValue(newValue);
  };

  const handleChangeTime2 = (newValue) => {
    setValue2(newValue);
  };

  console.log(value);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        paddingBottom: "30px",
      }}
    >
      {/* <CaseContainer> */}
      <Div
        sx={{
          marginLeft: { xs: "0%", md: "18%" },
          marginRight: { xs: "0%", md: "18%" },

          height: "100%",
        }}
      >
        {/* <form> */}
        <Autocomplete
          value={formik.values.lawyer}
          onChange={(event, value) => setLawyer(value)}
          style={{ width: "100%" }}
          multiple
          options={LawyerType}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          sx={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              sx={{ mb: 2 }}
              {...params}
              label="Lawyer Type*"
              color="secondary"
              placeholder="Lawyer Type"
              error={formik.errors.lawyer}
              name="lawyer"
              helperText={formik.errors.lawyer}
            />
          )}
        />

        <Autocomplete
          value={formik.values.court}
          onChange={(event, value) => setCourt(value)}
          style={{ width: "100%" }}
          multiple
          options={PracticeCourt}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          sx={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              sx={{ mb: 0, mb: 2 }}
              {...params}
              label="Select Court Type*"
              color="secondary"
              placeholder="Select Court Type"
              error={formik.errors.court}
              name="court"
              helperText={formik.errors.court}
            />
          )}
        />
        <Div sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormControlLabel
            control={<Checkbox color="secondary" />}
            label="Address same as personal"
          />
          <Button
            style={{ fontWeight: "700" }}
            startIcon={<AddIcon />}
            color="secondary"
          >
            Add New
          </Button>
        </Div>
        <Div>
          <Typography
            variant="h6"
            sx={{
              // fontSize: { xs: "12px", md: "16px" },
              fontWeight: "700",
              mb: 2,
              mt: 2,
            }}
          >
            Availability
          </Typography>
          <Div sx={{ display: "flex", gap: 1, alignItems: "center", mt: 1 }}>
            <ErrorOutlineIcon color="secondary" />

            <Typography
              sx={{
                fontSize: { xs: "10px", md: "12px" },
              }}
            >
              Set availability according to every day
            </Typography>
          </Div>
        </Div>
        <Div sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Div
            sx={{
              display: "flex",
              mt: 2,
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Button
              onClick={() => handleDayChange("mon")}
              style={{
                backgroundColor: initialState["mon"] ? "black" : "white",
              }}
              color="secondary"
              disableElevation
            >
              Mon
            </Button>
            <Button
              onClick={() => handleDayChange("tue")}
              style={{
                backgroundColor: initialState["tue"] ? "black" : "white",
              }}
              color="secondary"
              disableElevation
            >
              Tue
            </Button>
            <Button
              onClick={() => handleDayChange("wed")}
              style={{
                backgroundColor: initialState["wed"] ? "black" : "white",
              }}
              color="secondary"
              disableElevation
            >
              Wed
            </Button>
            <Button
              onClick={() => handleDayChange("thur")}
              style={{
                backgroundColor: initialState["thur"] ? "black" : "white",
              }}
              color="secondary"
              disableElevation
            >
              Thur
            </Button>
            <Button
              onClick={() => handleDayChange("fri")}
              style={{
                backgroundColor: initialState["fri"] ? "black" : "white",
              }}
              color="secondary"
              disableElevation
            >
              Fri
            </Button>
            <Button
              onClick={() => handleDayChange("sat")}
              style={{
                backgroundColor: initialState["sat"] ? "black" : "white",
              }}
              color="secondary"
              disableElevation
            >
              Sat
            </Button>

            <Button
              onClick={() => handleDayChange("sun")}
              style={{
                backgroundColor: initialState["sun"] ? "black" : "white",
              }}
              color="secondary"
              disableElevation
            >
              Sun
            </Button>
          </Div>
        </Div>
        <Div
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "12px", md: "14px" },
            }}
          >
            Open or Close at Monday
          </Typography>
          <Switch checked={checked} onChange={handleChange} color="secondary" />
        </Div>
        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 3,
            mt: 2,
            mb: 2,
          }}
        >
          <Div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label={"Opens at"}
                value={value}
                onChange={handleChangeTime1}
                renderInput={(params) => (
                  <TextField
                    color="secondary"
                    onChange={(e) => setValue(e.target.value)}
                    // error={formik.errors.value}
                    // helperText={formik.errors.value}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
            {formik.errors.value && (
              <Typography
                sx={{ fontSize: "10px", color: "#E73145", ml: 1.8, mt: 0.3 }}
              >
                Please choose a time!
              </Typography>
            )}
          </Div>

          {/* <TimePickerComponent label="Opens at" /> */}
          <Div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label={"Closes at"}
                value={value2}
                onChange={handleChangeTime2}
                renderInput={(params) => (
                  <TextField color="secondary" {...params} />
                )}
              />
            </LocalizationProvider>
            {formik.errors.value2 && (
              <Typography
                sx={{ fontSize: "10px", color: "#E73145", ml: 1.8, mt: 0.3 }}
              >
                Please choose a time!
              </Typography>
            )}
          </Div>
        </Div>
        <FormControlLabel
          control={<Checkbox color="secondary" />}
          label="Repeat For All Days"
        />
        {/* </form> */}
        <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          <LoadingButton
            onClick={handleBackButton}
            // disabled={values !== 0 ? false : true}
            variant="contained"
            sx={{ width: 100, padding: 1 }}
          >
            Back
          </LoadingButton>
          <LoadingButton
            onClick={formik.handleSubmit}
            color="secondary"
            style={{
              backgroundColor: "black",
            }}
            sx={{ width: 100, padding: 1 }}
            variant="contained"
          >
            Continue
          </LoadingButton>
        </Div>
      </Div>
    </div>
  );
};

export default OfficialDetails;
