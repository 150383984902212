import { apiHeader, apiUrl } from "app/config";

export const updateProfilePicture = async (accessToken, image) => {
  try {
    // console.log("access token in profile ", image);
    var formData = new FormData();
    formData.append("profileimage", image);

    let headers = new Headers();

    headers.append("x-api-key", apiHeader);
    headers.append("Authorization", "Bearer " + accessToken);

    const response = await fetch(apiUrl + "/api/v1/user/image", {
      method: "POST",
      headers,
      body: formData,
    });
    if (response.status === 200) {
      const data = await response.json();
      console.log(data);
      return { data: data };
    }
  } catch (e) {
    console.log(e);
  }
};

export const LawyerProfileUpdate = async (accessToken, obj) => {
  try {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    // headers.append('Authorization', 'Bearer ' + 'application/json');

    headers.append("x-api-key", apiHeader);
    headers.append("Authorization", "Bearer " + accessToken);
    const response = await fetch(apiUrl + "/api/v1/lawyer/profile", {
      method: "POST",
      headers,
      body: JSON.stringify(obj),
    });
    // console.log(response);

    if (response.status === 200) {
      const data = await response.json();
      // console.log(data);
      return { data: data };
    }
  } catch (e) {}
};

export const UpdateUserProfile = async (
  mobile,
  name,
  email,
  role,
  accessToken
) => {
  try {
    const obj = {
      phone: mobile,
      fullname: name,
      email: email,
      password: mobile,
      alternate: true,
      roles: [role],
    };
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    // headers.append('Authorization', 'Bearer ' + 'application/json');

    headers.append("x-api-key", apiHeader);
    headers.append("Authorization", "Bearer " + accessToken);
    const response = await fetch(apiUrl + "/api/v1/user/profile", {
      method: "POST",
      headers,
      body: JSON.stringify(obj),
    });
    console.log(response);

    if (response.status === 200) {
      const data = await response.json();
      console.log(data);
      return { data: data };
    }
  } catch (e) {
    console.log(e);
  }
};
