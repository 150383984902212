import { string } from "prop-types";
import * as Yup from "yup";

export const loginValidations = Yup.object().shape({
  fullname: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .required("Name is required!"),
  email: Yup.string().email("Invalid email").required("Email is required"),

  mobile: Yup.number()
    .typeError("That doesn't look like a phone number")
    .positive("A phone number can't start with a minus")
    .integer("A phone number can't include a decimal point")
    .required("Phone Number is required"),
});

export const loginValidations2 = Yup.object().shape({
  barcouncilnumber: Yup.string().required(
    "Bar Council Number number is required!"
  ),

  // state: Yup.array()
  //   .max(10, "Only 10 tags are allowed")
  //   .required("Provide at least one tag"),
});

export const todoValidations = Yup.object().shape({
  case: Yup.array()
    .min(1, "Please select atleast one case!")
    .required("Please select atleast one case!"),
  taskName: Yup.string().required("Task Name is required!"),
  taskDescription: Yup.string().required("Task Description is required!"),
  assignedBy: Yup.array()
    .min(1, "Please select atleast one Assigned By!")
    .required("Please select atleast one Assigned By!"),
  assignedTo: Yup.array()
    .min(1, "Please select atleast one Assigned To!")
    .required("Please select atleast one Assigned To!"),
  documentName: Yup.string().required("Document name is required!"),
  priority: Yup.string().required("Please select a priority!"),
});

export const documentValidations = Yup.object().shape({
  case: Yup.array()
    .min(1, "Please select atleast one Case!")
    .required("Please select atleast one Case!"),
  caseLawyer: Yup.array()
    .min(1, "Please select atleast one case Lawyer!")
    .required("Please select atleast one case Lawyer!"),

  client: Yup.array()
    .min(1, "Please select atleast one Client!")
    .required("Please select atleast one Client!"),
  documentName: Yup.string().required("Document name is required!"),

  documentDescription: Yup.string().required("Task Description is required!"),
});

export const casePointValidations = Yup.object().shape({
  case: Yup.array()
    .min(1, "Please select atleast one Case!")
    .required("Please select atleast one Case!"),
  caseLawyer: Yup.array()
    .min(1, "Please select atleast one Case!")
    .required("Please select atleast one Case!"),

  client: Yup.array()
    .min(1, "Please select atleast one Client!")
    .required("Please select atleast one Client!"),
  lawPoint: Yup.string().required("Document name is required!"),
  linkedDocuments: Yup.array()
    .min(1, "Please select atleast one Linked Documents!")
    .required("Please select atleast one Linked Documents!"),
  casePointDescription: Yup.string().required("Task Description is required!"),
});

//My cases ->

export const CaseValidations = Yup.object().shape({
  caseTitle: Yup.string()
    //.min(1, "Please select atleast one value!"),
    .required("Case Title is required"),

  caseType: Yup.array()
    .min(1, "Please select atleast one Case Type!")
    .required("Please select atleast one Case Type!"),

  underSection: Yup.array()
    .min(1, "Please select atleast one Under Section!")
    .required("Please select atleast one Under Section!"),

  assignLawyer: Yup.array()
    .min(1, "Please select atleast one Assign Lawyer!")
    .required("Please select atleast one Assign Lawyer!"),

  description: Yup.string().required("Description is required!"),
});

export const HearingValidations = Yup.object().shape({
  // startingDate: startingDate,
  // casePoints: casePoints,
  // currentDate: currentDate,
  // documentName: documentName,
  // hearingDescription: hearingDescription,

  startingDate: Yup.string(),
  //.min(1, "Please select atleast one value!"),
  // .required("starting date is required!"),
  casePoints: Yup.string()
    //.min(1, "Please select atleast one value!"),
    .required("Case Points is required!"),

  currentDate: Yup.string(),
  //.min(1, "Please select atleast one value!"),
  //.required("current date is required!"),

  documentName: Yup.string()
    //.min(1, "Please select atleast one value!"),
    .required("Please select atleast one value!"),

  hearingDescription: Yup.string().required("Description is required!"),
});

export const ClientValidations = Yup.object().shape({
  // startingDate: startingDate,
  // casePoints: casePoints,
  // currentDate: currentDate,
  // documentName: documentName,
  // hearingDescription: hearingDescription,

  fullname: Yup.string().required("Full Name is required!"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  num: Yup.number()
    .typeError("That doesn't look like a phone number")
    .positive("A phone number can't start with a minus")
    .integer("A phone number can't include a decimal point")
    .required("Phone Number is required")

    // num: Yup.string()
    // .matches(/^[6-9]\d{9}$/, {
    //   message: "Please enter valid number.",
    //   excludeEmptyString: false,
    // })

    .required("Mobile number is required!"),
  house: Yup.string().required("House is required!"),
  city: Yup.string().required("City is required!"),
  pin: Yup.number()
    .typeError("That doesn't look like a Pin Code")
    .positive("A Pin Code can't start with a minus")
    .integer("A Pin Code can't include a decimal point")

    .required("Pin Code is required!"),
  state: Yup.string().required("State is required!"),
});

export const PersonalDetailsValidations = Yup.object().shape({
  fullName: Yup.string().required("Full Name is required!"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  age: Yup.number()
    .required("Age is required")
    .min(1, "You must be at least 1 year")
    .max(60, "You must be at most 60 years"),
  gender: Yup.string().required("Please select a Gender!"),

  // date: Yup.string().required("Please select a date!"),

  phone: Yup.string()
    .matches(/^[6-9]\d{9}$/, {
      message: "Please enter valid number.",
      excludeEmptyString: false,
    })
    .required("Phone number is required"),
});

export const OfficialDetailsValidations = Yup.object().shape({
  lawyer: Yup.array()
    .min(1, "Please select atleast one lawyer!")
    .required("Please select atleast one lawyer!"),
  court: Yup.array()
    .min(1, "Please select atleast one court!")
    .required("Please select atleast one court!"),
  value: Yup.string().required("Please select atleast one court!"),
  value2: Yup.string().required("Please select atleast one court!"),
});

export const PaymentDetailsValidations = Yup.object().shape({
  lawyer: Yup.array()
    .min(1, "Please select atleast one lawyer!")
    .required("Please select atleast one lawyer!"),
  court: Yup.array()
    .min(1, "Please select atleast one court!")
    .required("Please select atleast one court!"),
});
