export const ROLE = "role";
export const USERNAME = "username";
export const ACCESSTOKEN = "accessToken";
export const PROFILESTATUS = "profileStatus";
export const NAME = "name";
export const EMAIL = "email";
export const MOBILE = "mobile";
export const PROFILEIMAGE = "profileimage";
export const EMAILVERIFIED = "emailverified";
export const MOBILEVERIFIED = "mobileverified";
