import { getAssetPath } from "../../utils/appHelpers";
import { ASSET_AVATARS } from "../../utils/constants/paths";

export const ClientData = [
  {
    id: 1,
    name: "Client Name",
    address: "15/64, Malviya Nagar, Jaipur 325205",
    email: "aryanary@gmail.com",
    phoneNumber: "+91 8003572052",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar4.jpg`, "40x40"),
  },
  {
    id: 2,
    name: "Client Name",
    address: "15/64, Malviya Nagar, Jaipur 325205",
    email: "aryanary@gmail.com",
    phoneNumber: "+91 8003572052",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar4.jpg`, "40x40"),
  },
  {
    id: 3,
    name: "Client Name",
    address: "15/64, Malviya Nagar, Jaipur 325205",
    email: "aryanary@gmail.com",
    phoneNumber: "+91 8003572052",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar4.jpg`, "40x40"),
  },
  {
    id: 4,
    name: "Client Name",
    address: "15/64, Malviya Nagar, Jaipur 325205",
    email: "aryanary@gmail.com",
    phoneNumber: "+91 8003572052",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar4.jpg`, "40x40"),
  },
  {
    id: 5,
    name: "Client Name",
    address: "15/64, Malviya Nagar, Jaipur 325205",
    email: "aryanary@gmail.com",
    phoneNumber: "+91 8003572052",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar4.jpg`, "40x40"),
  },
  {
    id: 6,
    name: "Client Name",
    address: "15/64, Malviya Nagar, Jaipur 325205",
    email: "aryanary@gmail.com",
    phoneNumber: "+91 8003572052",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar4.jpg`, "40x40"),
  },
];