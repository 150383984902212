import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import { authUser } from "./fake-db";
import {
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RepeatOutlinedIcon from "@mui/icons-material/RepeatOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";

import { useDispatch, useSelector } from "react-redux";
import {
  defaultAccessToken,
  DefaultName,
  DefaultProfileImage,
  defaultProfileStatus,
  defaultRole,
  DefaultUserEmail,
  DefaultUserMobile,
  DefaultUserName,
} from "app/redux/actions/OnboardingInputs";

import {
  UserBarCouncil,
  UserPracticeStates,
  UserPracticeCourts,
  UserLawyerEducation,
  UserLawyerSpecialisation,
  UserLawyerLanguage,
} from "app/redux/actions/UpdatedInputs";
import PersonIcon from "@mui/icons-material/Person";
import { rootReducer } from "app/redux/reducers";
// import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { setAuthToken } from "app/services/auth/jwtAuth";

const HorizontalAuthUserDropdown = () => {
  const navigate = useNavigate();
  const { theme } = useJumboTheme();
  // const { setAuthToken } = useJumboAuth();
  const dispatch = useDispatch();
  const profileImage = useSelector(
    (state) => state?.onboardingInputs?.profileImage
  );

  useEffect(() => {}, [profileImage]);

  const username = useSelector((state) => state?.onboardingInputs?.username);
  const fullname = useSelector((state) => state?.onboardingInputs?.fullName);

  const onLogout = () => {
    setAuthToken(null);

    dispatch(defaultAccessToken(""));
    dispatch(DefaultUserEmail(""));
    dispatch(DefaultName(""));
    dispatch(DefaultUserName(""));
    dispatch(DefaultProfileImage(""));
    dispatch(DefaultUserMobile(""));
    dispatch(defaultProfileStatus(""));
    dispatch(defaultRole(""));

    dispatch(UserBarCouncil(""));
    dispatch(UserPracticeStates(""));
    dispatch(UserPracticeCourts(""));
    dispatch(UserLawyerEducation(""));
    dispatch(UserLawyerSpecialisation(""));
    dispatch(UserLawyerLanguage(""));

    dispatch(rootReducer);

    navigate("/");
  };

  return (
    <ThemeProvider theme={theme}>
      <JumboDdPopover
        triggerButton={
          <Avatar
            src={profileImage ? profileImage : <PersonIcon sx={{}} />}
            sizes={"small"}
            sx={{ boxShadow: 25, cursor: "pointer" }}
          />
        }
      >
        <Div
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            p: (theme) => theme.spacing(2.5),
            width: "250px",
          }}
        >
          <Avatar
            src={profileImage ? profileImage : <PersonIcon sx={{}} />}
            alt={authUser.name}
            sx={{ width: 60, height: 60, mb: 2 }}
          />
          <Typography
            sx={{
              fontSize: "16px",
              whiteSpace: "nowrap",
              fontWeight: 500,
              pl: 4,
              pr: 4,
            }}
          >
            {fullname ? fullname : "Lawinzo User"}
          </Typography>
          <Typography
            variant={"body1"}
            sx={{ fontSize: "14px" }}
            color="text.secondary"
          >
            {/* {authUser.handle} */}
            {username ? username : "no User logged in!"}
          </Typography>
        </Div>
        <Divider />
        <nav>
          <List disablePadding sx={{ pb: 1 }}>
            {}

            <ListItemButton onClick={onLogout}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ my: 0 }} />
            </ListItemButton>
          </List>
        </nav>
      </JumboDdPopover>
    </ThemeProvider>
  );
};

export default HorizontalAuthUserDropdown;
