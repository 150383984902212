import React from "react";
import TeamCard from "../components/TeamCard";
import TaskCard from "./TaskCard";
import { taskData } from "./CardData";
const TaskList = () => {
  return (
    <React.Fragment>
      {taskData.map((team, index) => (
        <TaskCard Key={team.id} item={team} />
      ))}
    </React.Fragment>
  );
};

export default TaskList;
