import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import React from "react";
import { useEffect } from "react";
import {
  Alert,
  Breadcrumbs,
  Button,
  CardContent,
  Snackbar,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { useState } from "react";
import { Card } from "@mui/material";
import { BookmarkAddOutlined, FilePresentOutlined } from "@mui/icons-material";
import CasePointDetails from "./CasePointDetails";
import CasePointListLayout from "./CasePointListLayout";
function AddCasePoints() {
  const [value, setValue] = React.useState(0);
  const [buttonValue, setButtonValue] = React.useState(0);
  const [error, setError] = React.useState([]);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  const [allDocuments, setAllDocuments] = useState(false);
  const [screen, setScreen] = useState(1);
  useEffect(() => {
    setAllDocuments(false);
  }, []);
  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
  };

  const handleBackButton = () => {
    if (value !== 0) {
      setButtonValue((prevButtonValue) => prevButtonValue - 1);
      setValue((prevButtonValue) => prevButtonValue - 1);
    }
  };

  const AllDocumentsDisplay = () => {
    setAllDocuments(true);
    handleScreen();
    console.log(screen);
  };

  const handleScreen = (value) => {
    setScreen(value ? value : (state) => state + 1);
  };
  if (screen === 1) {
    return (
      <Div sx={{ height: "100%", width: "100%" }}>
        <Div sx={{ mb: 2, mt: -3 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} fontSize="inherit" />
              Home
            </Typography>

            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <BookmarkAddOutlined sx={{ fontSize: 20 }} />
              &nbsp; Case Points
            </Typography>
          </Breadcrumbs>
        </Div>

        <Card
          sx={{
            backgroundColor: "white",
            height: "100%",
          }}
        >
          <CardContent>
            <Div
              sx={{
                height: "100%",
                position: "relative",
                paddingTop: "10px",
                //  paddingBottom: "0px",
              }}
            >
              <Div
                sx={{
                  width: "100%",
                  position: "relative",

                  height: "fit-content",
                  // height: "10%",
                  mt: -2,
                  mb: 8,
                }}
              >
                <Div
                  sx={{
                    position: "absolute",

                    borderBottom: "1px solid #D3D3D3 ",
                    width: "100%",
                    pt: 0,
                    pb: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  <Breadcrumbs aria-label="breadcrumb">
                    {/* <GavelOutlined sx={{ fontSize: 20 }} /> */}
                    <Typography
                      variant="h6"
                      color="text.primary"
                      // sx={{ marginLeft: "10px" }}
                    >
                      Case Points
                    </Typography>
                  </Breadcrumbs>

                  <Typography
                    variant="h6"
                    color="text.primary"
                    sx={{ cursor: "pointer" }}
                    //   onClick={resetValue}
                    // sx={{ marginLeft: "10px" }}
                  >
                    {/* Reset */}
                  </Typography>
                </Div>
              </Div>
              {/* breadcrum */}
              <Div
                sx={{
                  pl: { sm: "0", lg: "11%" },
                  pr: { sm: "0", lg: "11%" },
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",

                  alignItems: "center",
                }}
              >
                {/* Add Task Area */}

                <Div
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    textAlign: "center",
                    alignItems: "center",
                    paddingLeft: { xs: "5%", md: "18%" },
                    paddingRight: { xs: "5%", md: "18%" },
                    mt: 1,
                  }}
                >
                  <LoadingButton
                    onClick={handleBackButton}
                    disabled={value !== 0 ? false : true}
                    style={{
                      backgroundColor: value !== 0 ? "black" : "#d6d9dc",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}
                    variant="contained"
                  >
                    Back
                  </LoadingButton>

                  <LoadingButton
                    onClick={() => AllDocumentsDisplay()}
                    style={{
                      backgroundColor: "black",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      width: "100px",
                    }}
                    variant="contained"
                  >
                    Submit
                  </LoadingButton>
                </Div>

                <CasePointDetails
                  handleBackButton={handleBackButton}
                  value={value}
                  AllDocumentsDisplay={AllDocumentsDisplay}
                />

              </Div>
            </Div>
          </CardContent>
        </Card>
      </Div>
    );
  } else if (screen === 2) {
    return <CasePointListLayout handleScreen={handleScreen} />;
  } else return <Div>hello</Div>;
}

export default AddCasePoints;
