import Div from "@jumbo/shared/Div";
import {
  Breadcrumbs,
  Button,
  Card,
  Divider,
  InputBase,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CardFooter from "../components/CardFooter";
import TeamSkeletons from "../Teams/TeamSkeletons";
import HomeIcon from "@mui/icons-material/Home";
import { GavelOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CaseList from "./CaseList";

const CaseListLayout = ({ setAddCase }) => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    // Cancel the timer while unmounting
    return () => clearTimeout(timer);
  }, []);
  return (
    <React.Fragment>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} />
          Home
        </Typography>

        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <GavelOutlined sx={{ mr: 0.5, fontSize: 20 }} />
          Cases
        </Typography>
      </Breadcrumbs>
      <Card
        sx={{
          p: 3,
          position: "relative",
        }}
      >
        <Div
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 5,
              }}
            >
              <Typography variant={"h5"}>My Cases</Typography>
              {/* Add New */}
              <Button
                style={{ fontWeight: "700" }}
                startIcon={<AddIcon />}
                color="secondary"
                onClick={() => setAddCase(true)}
              >
                Add Case
              </Button>
            </Div>
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              {/* Search Bar */}
              <Div
                sx={{
                  position: "relative",
                  width: "90%",
                }}
              >
                <Div
                  sx={{
                    padding: (theme) => theme.spacing(0, 1),
                    height: "100%",
                    position: "absolute",
                    pointerEvents: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 2,
                  }}
                >
                  <SearchIcon color="secondary" />
                </Div>
                <InputBase
                  id="search-bar"
                  className="search"
                  placeholder="Search the client"
                  size="small"
                  sx={{
                    color: "inherit",
                    display: "flex",
                    borderRadius: 24,
                    backgroundColor: (theme) =>
                      theme.jumboComponents.JumboSearch.background,

                    "& .MuiInputBase-input": {
                      padding: (theme) => theme.spacing(1, 1, 1, 0),
                      paddingLeft: (theme) => `calc(1em + ${theme.spacing(3)})`,
                      transition: (theme) => theme.transitions.create("width"),
                      width: "100%",
                      height: 24,
                    },
                  }}
                />
              </Div>
              <Div sx={{ width: "60%" }}>
                <Button
                  style={{ fontWeight: "600" }}
                  startIcon={<FilterAltOutlinedIcon />}
                  color="secondary"
                  size="small"
                >
                  Filter By
                </Button>
              </Div>
            </Div>
          </Div>
          <Divider sx={{ mt: 2 }} />
          <Div sx={{ mb: 10 }}>
            {loading && <TeamSkeletons />}
            {!loading && <CaseList />}
          </Div>
          <Div
            sx={{ position: "absolute", bottom: 20, right: { sm: 0, lg: 25 } }}
          >
            <CardFooter />
          </Div>
        </Div>
      </Card>
    </React.Fragment>
  );
};

export default CaseListLayout;
