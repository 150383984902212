import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import contactsApp from "./contactsApp";
import onboardingInputs from "./OnboardingInputs";
import updatedInputs from "./UpdatedInputs";

const exportReducers = (history) => {
  return combineReducers({
    router: connectRouter(history),
    contactsApp: contactsApp,
    onboardingInputs: onboardingInputs,
    updateInputs: updatedInputs,
  });
};
export const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return exportReducers(undefined, action);
  }

  return exportReducers(state, action);
};

export default exportReducers;
