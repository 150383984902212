import {
  ACCESSTOKEN,
  EMAIL,
  MOBILE,
  NAME,
  PROFILEIMAGE,
  PROFILESTATUS,
  ROLE,
  USERNAME,
  EMAILVERIFIED,
  MOBILEVERIFIED,
} from "../../utils/constants/LawinzoLogin";

const INIT_STATE = {
  assignedRole: "",
  username: "",
  accessToken: "",
  profileStatus: "",
  fullName: "",
  emailId: "",
  mobileNumber: "",
  profileImage: "",
  mobileVerified: "",
  emailVerified: "",
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ROLE: {
      return {
        ...state,
        assignedRole: action.payload,
      };
    }

    case USERNAME: {
      return {
        ...state,
        username: action.payload,
      };
    }
    case ACCESSTOKEN: {
      return {
        ...state,
        accessToken: action.payload,
      };
    }

    case PROFILESTATUS: {
      return {
        ...state,
        profileStatus: action.payload,
      };
    }

    case NAME: {
      return {
        ...state,
        fullName: action.payload,
      };
    }

    case EMAIL: {
      return {
        ...state,
        emailId: action.payload,
      };
    }

    case MOBILE: {
      return {
        ...state,
        mobileNumber: action.payload,
      };
    }

    case PROFILEIMAGE: {
      return {
        ...state,
        profileImage: action.payload,
      };
    }

    case EMAILVERIFIED: {
      return {
        ...state,
        emailVerified: action.payload,
      };
    }

    case MOBILEVERIFIED: {
      return {
        ...state,
        mobileVerified: action.payload,
      };
    }

    case "USER_LOGOUT": {
      return {
        state,
      };
    }
    //   case CONTACT_EDIT_STARTED: {
    //     return {
    //       ...state,
    //       currentContact: action.payload,
    //       visibleDialog: VISIBLE_DIALOG_OPTIONS.CONTACT_EDIT,
    //     };
    //   }

    default: {
      return state;
    }
  }
};

export default reducer;
