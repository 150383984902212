import Div from "@jumbo/shared/Div";
import React from "react";
import { Breadcrumbs, Card, CardContent, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { GroupOutlined } from "@mui/icons-material";
import SplitButton from "./SplitButton";
import Client from "./Client";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Witness from "./Witness";
import OppositeClient from "./OppositeClient";

const options = ["Client", "Witness", "Opposite Client"];

const AddClientLayout = () => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  return (
    <Div sx={{ height: "100%", width: "100%" }}>
      <Div sx={{ mb: 2, mt: -3 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} />
            Home
          </Typography>

          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <GroupOutlined sx={{ fontSize: 20 }} />
            &nbsp; Client
          </Typography>
        </Breadcrumbs>
      </Div>

      <Card
        sx={{
          diplay: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <CardContent>
          <Div
            sx={{
              width: "100%",
              height: "fit-content",
              mb: 8,
            }}
          >
            <Div
              sx={{
                borderBottom: "1px solid #D3D3D3 ",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 1,
                flexWrap: "wrap",
              }}
            >
              <Typography variant="h5" color="text.primary">
                Client
              </Typography>
              {/* Spit BUtton code */}
              <Div>
                <React.Fragment>
                  <ButtonGroup
                    variant="contained"
                    ref={anchorRef}
                    aria-label="split button"
                    color="secondary"
                  >
                    <Button
                      onClick={handleClick}
                      sx={{
                        width: 140,
                        padding: 1,
                        color: "black",
                        pointerEvents: "none",
                      }}
                      disableElevation
                    >
                      {options[selectedIndex]}
                    </Button>
                    <Button
                      size="small"
                      aria-controls={open ? "split-button-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-label="select merge strategy"
                      aria-haspopup="menu"
                      onClick={handleToggle}
                      disableElevation
                      sx={{ color: "black" }}
                      style={{ backgroundColor: "#EABF4E" }}
                    >
                      <ArrowDropDownIcon />
                    </Button>
                  </ButtonGroup>
                  <Popper
                    sx={{
                      zIndex: 1,
                    }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu" autoFocusItem>
                              {options.map((option, index) => (
                                <MenuItem
                                  key={option}
                                  selected={index === selectedIndex}
                                  onClick={(event) =>
                                    handleMenuItemClick(event, index)
                                  }
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </React.Fragment>
              </Div>
              <Typography
                variant="h6"
                color="text.primary"
                sx={{ cursor: "pointer" }}
              >
                Reset
              </Typography>
            </Div>
            <Div sx={{ display: { xs: "block", lg: "none" } }}>
              {/* Only in phone  */}
              {/* <React.Fragment sx={{ display: { sm: "block", lg: "hidden" } }}>
              <ButtonGroup
                variant="contained"
                ref={anchorRef}
                aria-label="split button"
                color="secondary"
              >
                <Button
                  onClick={handleClick}
                  sx={{
                    width: 150,
                    padding: 1,
                    color: "black",
                    pointerEvents: "none",
                  }}
                  disableElevation
                >
                  {options[selectedIndex]}
                </Button>
                <Button
                  size="small"
                  aria-controls={open ? "split-button-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                  disableElevation
                  sx={{ color: "black" }}
                  style={{ backgroundColor: "#EABF4E" }}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          {options.map((option, index) => (
                            <MenuItem
                              key={option}
                              selected={index === selectedIndex}
                              onClick={(event) =>
                                handleMenuItemClick(event, index)
                              }
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </React.Fragment> */}
            </Div>
          </Div>

          <Div
            sx={{
              pl: { sm: "0", lg: "11%" },
              pr: { sm: "0", lg: "11%" },
              m: "auto",
            }}
          >
            {selectedIndex === 0 ? (
              <Client />
            ) : selectedIndex === 1 ? (
              <Witness />
            ) : (
              <OppositeClient />
            )}
          </Div>
        </CardContent>
      </Card>
    </Div>
  );
};

export default AddClientLayout;
