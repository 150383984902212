export const CaseData = [
  {
    id: 1,
    title: "Case title 01",
    sectionType: "Section Type",
    status: "Open Case",
    client: "Vijay Singh",
    lawyer: "Mukesh Jain",
    court: "High Court",
    startingDate: "10-Nov-2019",
    NextHearing: "13-Dec-2019",
  },
  {
    id: 2,
    title: "Case title 01",
    sectionType: "Section Type",
    status: "Close Case",
    client: "Vijay Singh",
    lawyer: "Mukesh Jain",
    court: "High Court",
    startingDate: "10-Nov-2019",
    NextHearing: "13-Dec-2019",
  },
  {
    id: 3,
    title: "Case title 01",
    sectionType: "Section Type",
    status: "Open Case",
    client: "Vijay Singh",
    lawyer: "Mukesh Jain",
    court: "High Court",
    startingDate: "10-Nov-2019",
    NextHearing: "13-Dec-2019",
  },
  {
    id: 4,
    title: "Case title 01",
    sectionType: "Section Type",
    status: "Close Case",
    client: "Vijay Singh",
    lawyer: "Mukesh Jain",
    court: "High Court",
    startingDate: "10-Nov-2019",
    NextHearing: "13-Dec-2019",
  },
  {
    id: 5,
    title: "Case title 01",
    sectionType: "Section Type",
    status: "Close Case",
    client: "Vijay Singh",
    lawyer: "Mukesh Jain",
    court: "High Court",
    startingDate: "10-Nov-2019",
    NextHearing: "13-Dec-2019",
  },
  {
    id: 6,
    title: "Case title 01",
    sectionType: "Section Type",
    status: "Close Case",
    client: "Vijay Singh",
    lawyer: "Mukesh Jain",
    court: "High Court",
    startingDate: "10-Nov-2019",
    NextHearing: "13-Dec-2019",
  },
];
