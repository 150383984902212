import React from "react";
import Div from "@jumbo/shared/Div";
import Link from "@mui/material/Link";
import { ASSET_IMAGES } from "../../utils/constants/paths";

const Logo = ({ mini, mode, sx }) => {
  return (
    <Div sx={{ display: "inline-flex", ...sx }}>
      <Link href={"/dashboards/profile"}>
        {!mini ? (
          <div style={{ width: "100%" }}>
            {" "}
            <img
              src={`${ASSET_IMAGES}/lawinzo/logo-yellow.png`}
              style={{
                objectFit: "contain",
                height: "14vh",
                width: "100%",
                marginBottom: "8px",
              }}
            />
          </div>
        ) : (
          <div style={{ width: "100%" }}>
            {" "}
            <img
              src={`${ASSET_IMAGES}/logo-white.png`}
              style={{ objectFit: "contain", height: "8vh", width: "100%" }}
            />
          </div>
        )}
      </Link>
    </Div>
  );
};

Logo.defaultProps = {
  mode: "light",
};

export default Logo;
