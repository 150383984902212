import React from "react";
import Page from "@jumbo/shared/Page";
import Login from "../pages/authPages/login";
import Otp from "app/pages/authPages/otp";

const authRoutes = [
  {
    path: "/",
    element: (
      <Page component={Login} layout={"solo-page"} disableSmLogin={false} />
    ),
  },
  {
    path: "/otp",
    element: (
      <Page component={Otp} layout={"solo-page"} disableSmLogin={true} />
    ),
  },
];

export default authRoutes;
