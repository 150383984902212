import Div from "@jumbo/shared/Div";
import React, { useEffect, useState } from "react";
import { NoCaseContainer } from "./style";
import NoCaseImage from "../../ImageAssets/NoCaseImage.png";
import { Button, Typography } from "@mui/material";
import AddCase from "./AddCase";
import { CaseData } from "./CaseData";
import CaseListLayout from "./CaseListLayout";
function NoCase() {
  const [addCase, setAddCase] = useState(false);

  useEffect(() => {
    setAddCase(false);
  }, []);

  if (addCase) {
    return <AddCase setAddCase={setAddCase} />;
  } else {
    return (
      <NoCaseContainer>
        {CaseData.length > 0 ? (
          <CaseListLayout setAddCase={setAddCase} />
        ) : (
          <Div
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Div
              sx={{
                width: { xs: "60%", sm: "30%", md: "150px" },
                height: "30%",
              }}
            >
              <img
                src={NoCaseImage}
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                alt=""
              />
            </Div>

            <Typography
              sx={{
                fontSize: { xs: "18px", md: "24px" },
                textAlign: "center",
                mt: 2,
              }}
            >
              You haven't added any records yet!
            </Typography>
            <Div
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mt: 4,
              }}
            >
              {/* <Link to="/lawyers/addcases" style={{ textDecoration: "none" }}> */}
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "secondary.main",
                  mb: 2,
                  width: "150px",
                }}
                onClick={() => setAddCase(true)}
              >
                Add Case
              </Button>
              {/* </Link> */}
              <Typography sx={{ mb: 2 }}>Or</Typography>
              <Typography
                sx={{
                  fontSize: { xs: "18px", md: "20px" },
                  color: "secondary.main",
                  fontWeight: 500,
                }}
              >
                Import Data
              </Typography>
            </Div>
          </Div>
        )}
      </NoCaseContainer>
    );
  }
}

export default NoCase;
