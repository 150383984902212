import Div from "@jumbo/shared/Div";
import { Typography } from "@mui/material";
import React from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function LoginFooter() {
  return (
    <Div sx={{ mt: 15 }}>
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Div
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 3,
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              color: "#EABF4E",
              fontSize: "12px",
              fontWeight: "500",
              mb: 2,
              cursor: "pointer",
            }}
          >
            Terms & Conditions
          </Typography>
          <Typography
            variant={"h2"}
            sx={{
              color: "#EABF4E",
              fontSize: "12px",
              fontWeight: "500",
              mb: 2,
              cursor: "pointer",
            }}
          >
            Privacy Policy
          </Typography>
          <Typography
            variant={"h2"}
            sx={{
              color: "#EABF4E",
              fontSize: "12px",
              fontWeight: "500",
              mb: 2,
              cursor: "pointer",
            }}
          >
            Help
          </Typography>
        </Div>
        <Div sx={{ display: "flex", alignItems: "start" }}>
          <Typography
            variant={"h2"}
            sx={{
              color: "#EABF4E",
              fontSize: "12px",
              fontWeight: "500",
              mb: 2,
              cursor: "pointer",
            }}
          >
            English
          </Typography>
          <KeyboardArrowUpIcon color="secondary" fontSize="inherit" />
        </Div>
      </Div>

      <Typography
        variant={"h2"}
        sx={{
          color: "#8F8F90",
          fontSize: "14px",
          fontWeight: "600",
          mb: 2,
        }}
      >
        Lawinzo is India's online legal service marketplace,that especially
        build for lawyers and law firms.
      </Typography>
      <Typography
        variant={"h2"}
        sx={{
          color: "#8F8F90",
          fontSize: "14px",
          fontWeight: "700",
        }}
      >
        © 2022 lawinzo.com @ All Rights Reserved
      </Typography>
    </Div>
  );
}

export default LoginFooter;
