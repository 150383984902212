// import React, { useEffect, useRef, useState } from "react";

// import { Button, Typography } from "@mui/material";
// import Div from "@jumbo/shared/Div";
// import EachClient from "./EachClient";
// import { LoadingButton } from "@mui/lab";
// import { useFormik } from "formik";
// import { ClientValidations } from "app/utils/validators";

// function Client({ handleBackButton, handleNextButton }) {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const formRef = useRef();
//   const [error, setError] = useState(true);
//   const [count, setCount] = useState(1);
//   const [AddClientEror, setAddClientError] = useState();

//   const AddClient = () => {
//     if (!formik.errors) setCount((state) => state + 1);
//     else {
//       setAddClientError("Please complete all details above!");
//     }
//   };

//   const [num, setNum] = React.useState();
//   const [pin, setPin] = React.useState();

//   const [fullname, setFullName] = useState();
//   const [email, setEmail] = useState();
//   const [house, setHosue] = useState();
//   const [city, setCity] = useState();
//   const [state, setState] = useState();

//   const formik = useFormik({
//     initialValues: {
//       fullname: fullname,
//       email: email,
//       house: house,
//       city: city,
//       state: state,
//       num: num,
//       pin: pin,
//     },
//     // innerRef: { formRef },
//     validationSchema: ClientValidations,
//     enableReinitialize: true,
//     onSubmit: (values) => {
//       console.log(values, "FORMIK");
//       handleNextButton();
//       setError(false);
//     },
//   });

//   const IncrementFn = () => {
//     error === false && handleNextButton();
//   };

//   return (
//     <div
//       style={{
//         height: "100%",
//         width: "100%",
//         paddingBottom: "30px",
//       }}
//     >
//       {/* <CaseContainer> */}
//       <Div
//         sx={{
//           marginLeft: { xs: "5%", md: "15%" },
//           marginRight: { xs: "5%", md: "15%" },

//           height: "100%",
//         }}
//       >
//         {Array(count)
//           .fill(count)
//           .map((x, idx) => (
//             <EachClient
//               count={idx}
//               clientNo={count}
//               setClientNo={setCount}
//               name={"Client"}
//               formik={formik}
//               setError={setError}
//               email={email}
//               setEmail={setEmail}
//               fullname={fullname}
//               setFullName={setFullName}
//               num={num}
//               setNum={setNum}
//               pin={pin}
//               setPin={setPin}
//               house={house}
//               setHosue={setHosue}
//               city={city}
//               setCity={setCity}
//               state={state}
//               setState={setState}
//             />
//           ))}
//         <Div
//           sx={{
//             display: "flex",
//             pr: 2,

//             justifyContent: "flex-end",
//           }}
//         >
//           <Button
//             onClick={AddClient}
//             sx={{ backgroundColor: "secondary.main" }}
//             style={{
//               margin: "0",
//               // marginTop: "-10px",
//               mr: 2,
//             }}
//             variant="contained"
//           >
//             Add New Client
//           </Button>
//           {AddClientEror && <Typography>{AddClientEror}</Typography>}
//         </Div>
//         <Div
//           sx={{
//             width: "100%",
//             pl: 2,
//             pr: 2,
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             mt: 5,
//             // backgroundColor: "green",
//           }}
//         >
//           <LoadingButton
//             onClick={handleBackButton}
//             //   disabled={values !== 0 ? false : true}
//             variant="contained"
//             sx={{ width: 130, padding: 1 }}
//           >
//             Back
//           </LoadingButton>
//           <LoadingButton
//             onClick={formik.handleSubmit}
//             color="secondary"
//             style={{
//               backgroundColor: "black",
//             }}
//             sx={{ width: 130, padding: 1 }}
//             variant="contained"
//           >
//             Continue
//           </LoadingButton>
//         </Div>
//       </Div>
//     </div>
//   );
// }

// export default Client;

import React, { useEffect, useState } from "react";

import { Alert, Button, Snackbar, Typography } from "@mui/material";

import Div from "@jumbo/shared/Div";
import EachClient from "./EachClient";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { ClientValidations } from "app/utils/validators";
import { add } from "date-fns";

function Client({ handleBackButton, handleNextButton }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [count, setCount] = useState(1);
  const [addClientClick, setAddClientClick] = useState(0);

  // const [num, setNum] = React.useState();
  // const [pin, setPin] = React.useState();

  // const [fullname, setFullName] = useState();
  // const [email, setEmail] = useState();
  // const [house, setHosue] = useState();
  // const [city, setCity] = useState();
  // const [state, setState] = useState();

  const [addClientError, setAddClientError] = useState();
  const [error, setError] = useState(true);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const [checkSubmit, setCheckSubmit] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const AddClient = () => {
    // if (
    //   !fullname ||
    //   fullname.length === 0 ||
    //   !email ||
    //   email.length === 0 ||
    //   (!house && house.length === 0) ||
    //   (!city && city.length === 0) ||
    //   (!state && state.length === 0) ||
    //   (!num && num.length === 0) ||
    //   (!pin && pin.length === 0) ||
    //   Object.keys(formik.errors).length !== 0
    // ) {
    //   // if (Object.keys(formik.errors).length === 0 && error === false) {
    //   //   setAddClientError(false);
    //   //   setCount((state) => state + 1);
    //   // } else
    //   //   console.log(formik.errors.length, formik.errors);
    //   //   setOpenSnackBar(true);
    //   //   //   setAddClientError("please fill in above details first!");
    //   // }
    //   setOpenSnackBar(true);
    // } else {
    setAddClientError(false);
    setCount((state) => state + 1);
    setAddClientClick((state) => state + 1);
    // }
  };

  console.log(count);
  // console.log(fullname, email, house, city, state, num, pin);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        paddingBottom: "30px",
      }}
    >
      {openSnackBar && (
        // <Div sx={{ width: "100%", height: "100%" }}>
        //   <ModalScreen
        //     open={open}
        //     setOpen={setOpen}
        //     handleClose={handleClose}
        //   />
        // </Div>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnackBar}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          sx={{ zIndex: 1000, mt: 10 }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%", pr: 3, pl: 3, borderRadius: "10px" }}
          >
            <Typography variant="h6">
              Please fill in above details first!
            </Typography>
            <Div sx={{ display: "flex", justifyContent: "center" }}></Div>
          </Alert>
        </Snackbar>
      )}
      {/* <CaseContainer> */}
      <Div
        sx={{
          marginLeft: { xs: "0%", md: "15%" },
          marginRight: { xs: "0%", md: "15%" },

          height: "100%",
        }}
      >
        {Array(count)
          .fill(count)
          .map((x, idx) => (
            <EachClient
              count={idx}
              clientNo={count}
              setClientNo={setCount}
              name={"Client"}
              addClientClick={addClientClick}
              // formik={formik}

              // num={num}
              // setNum={setNum}
              // pin={pin}
              // setPin={setPin}
              // setFullName={setFullName}
              // setEmail={setEmail}
              // setHosue={setHosue}
              // setCity={setCity}
              // setState={setState}
              handleNextButton={handleNextButton}
              checkSubmit={checkSubmit}
              setCheckSubmit={setCheckSubmit}
            />
          ))}
        <Div
          sx={{
            display: "flex",
            pr: 2,

            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={AddClient}
            sx={{ backgroundColor: "secondary.main" }}
            style={{
              margin: "0",
              // marginTop: "-10px",
              mr: 2,
            }}
            variant="contained"
          >
            Add New Client
          </Button>
          {addClientError && <Typography>{addClientError}</Typography>}
        </Div>
        <Div
          sx={{
            width: "100%",
            pl: 2,
            pr: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 5,
            // backgroundColor: "green",
          }}
        >
          <LoadingButton
            onClick={handleBackButton}
            //   disabled={values !== 0 ? false : true}
            variant="contained"
            sx={{ width: 100, padding: 1 }}
          >
            Back
          </LoadingButton>
          <LoadingButton
            onClick={() => setCheckSubmit(true)}
            color="secondary"
            style={{
              backgroundColor: "black",
            }}
            sx={{ width: 100, padding: 1 }}
            variant="contained"
          >
            Continue
          </LoadingButton>
        </Div>
      </Div>
    </div>
  );
}

export default Client;
