import React from "react";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import EventNoteIcon from "@mui/icons-material/EventNote";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import LoginIcon from "@mui/icons-material/Login";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import LockResetIcon from "@mui/icons-material/LockReset";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import ScreenLockRotationIcon from "@mui/icons-material/ScreenLockRotation";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RunningWithErrorsOutlinedIcon from "@mui/icons-material/RunningWithErrorsOutlined";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import StreamOutlinedIcon from "@mui/icons-material/StreamOutlined";
import ListIcon from "@mui/icons-material/List";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import CallEndIcon from "@mui/icons-material/CallEnd";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import GridViewIcon from "@mui/icons-material/GridView";
import Grid3x3OutlinedIcon from "@mui/icons-material/Grid3x3Outlined";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import BallotOutlinedIcon from "@mui/icons-material/BallotOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import {
  GavelOutlined,
  BookmarkAddOutlined,
  Diversity3Outlined,
  GroupOutlined,
  FilePresentOutlined,
  ManageAccountsOutlined,
  PaymentsOutlined,
} from "@mui/icons-material";
import { useSelector } from "react-redux";

export const LawyerMenu = [
  {
    label: "Main Menu",
    type: "section",
    children: [
      {
        uri: "/lawyers/dashboards",
        label: "Dashboard",
        type: "nav-item",
        icon: <DashboardOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/lawyers/schedules",
        label: "Schedules",
        type: "nav-item",
        icon: <CalendarMonthOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/lawyers/cases",
        label: "My Cases",
        type: "nav-item",
        icon: <GavelOutlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/lawyers/todo",
        label: "To Do Task",
        type: "nav-item",
        icon: <ListAltOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/lawyers/payments",
        label: "Payments",
        type: "nav-item",
        icon: <PaymentsOutlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/lawyers/clients",
        label: "Clients",
        type: "nav-item",
        icon: <GroupOutlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/lawyers/documents",
        label: "Documents",
        type: "nav-item",
        icon: <FilePresentOutlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/lawyers/casepoints",
        label: "Case Points",
        type: "nav-item",
        icon: <BookmarkAddOutlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/lawyers/teams",
        label: "Teams",
        type: "nav-item",
        icon: <Diversity3Outlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/lawyers/settings",
        label: "Settings",
        type: "nav-item",
        icon: <ManageAccountsOutlined sx={{ fontSize: 20 }} />,
      },
    ],
  },
  {
    label: "See Others",
    type: "section",
    children: [
      {
        uri: "/about-us",
        label: "About Us",
        type: "nav-item",
        icon: <InfoOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/contact-us",
        label: "FAQs",
        type: "nav-item",
        icon: <QuizOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/terms",
        label: "Terms & Conditions",
        type: "nav-item",
        icon: <StreamOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/help",
        label: "Help & Support",
        type: "nav-item",
        icon: <SupportAgentOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/refer",
        label: "Refer",
        type: "nav-item",
        icon: <ShareOutlinedIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
];

export const ClientMenu = [
  {
    label: "See Others",
    type: "section",
    children: [
      {
        uri: "/about-us",
        label: "About Us",
        type: "nav-item",
        icon: <InfoOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/contact-us",
        label: "FAQs",
        type: "nav-item",
        icon: <QuizOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/terms",
        label: "Terms & Conditions",
        type: "nav-item",
        icon: <StreamOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/help",
        label: "Help & Support",
        type: "nav-item",
        icon: <SupportAgentOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/refer",
        label: "Refer",
        type: "nav-item",
        icon: <ShareOutlinedIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
];

// export default menus;
