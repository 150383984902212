import React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Avatar from "@mui/material/Avatar";

import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { alpha, Paper, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import Div from "@jumbo/shared/Div";
import { taskData } from "./CardData";
import ActionsMenu from "./ActionsMenu";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ProjectItem = ({ item }) => {
  //const [expanded, setExpanded] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    if (taskData.find((team) => team.id === item.id)) setExpanded(!expanded);
  };

  return (
    <Paper
      elevation={0}
      variant="outlined"
      square
      sx={{
        mt: 1,
        "&:hover": {
          boxShadow: `0 3px 10px 0 ${alpha("#000", 0.2)}`,
          transform: "translateY(-4px)",
          borderBottomColor: "transparent",
        },
      }}
    >
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          px: 1,
          flexDirection: "row-reverse",

          "& .MuiAccordionSummary-content": {
            alignItems: "center",

            "&.Mui-expanded": {
              margin: "12px 0",
            },
          },
          ".MuiAccordionSummary-expandIconWrapper": {
            borderRadius: 1,
            border: 1,
            color: "text.secondary",
            borderColor: "divider",
            transform: "none",
            height: 28,
            width: 28,
            alignItems: "center",
            justifyContent: "center",
            mr: 1,

            "&.Mui-expanded": {
              transform: "none",
              color: "primary.main",
              borderColor: "primary.main",
            },

            "& svg": {
              fontSize: "1.25rem",
            },
          },
        }}
      >
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          {expanded ? <RemoveIcon /> : <AddIcon />}
        </ExpandMore>
        <Div sx={{ flexShrink: 0, px: 1 }}>
          <Avatar
            sx={{ width: { sm: 50, lg: 70 }, height: { sm: 50, lg: 70 } }}
            alt={item.name}
            src={item.profilePic}
            variant="rounded"
          />
        </Div>
        <Div
          sx={{
            width: { xs: "auto", lg: "15%" },
            flexShrink: 0,
            px: 1,
            flex: { xs: "1", lg: "0 1 auto" },
          }}
        >
          <Typography mb={0.5} fontSize={18} fontWeight="700" color="primary">
            {item.name}
          </Typography>
          <Typography
            variant={"h3"}
            color={"text.secondary"}
            mb={1}
            fontSize={14}
            fontWeight="700"
          >
            {item.taskName}
          </Typography>
        </Div>

        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "25%",
            flexShrink: 0,
            px: 2,
          }}
        >
          <Chip
            label={`Priority: ${item.priority}`}
            color="warning"
            size="medium"
            sx={{ fontSize: 14, fontWeight: "700" }}
          />
        </Div>
        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "20%",
            flexShrink: 0,
            px: 1,
          }}
        >
          <Typography
            fontSize={"16px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
          >
            Assigned To
          </Typography>
          <Div sx={{ display: "flex", gap: 1 }}>
            {/* <CalendarMonthIcon color="secondary" /> */}
            <Typography fontSize={"14px"}>{item.AssignedTo}</Typography>
          </Div>
        </Div>
        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "30%",
            flexShrink: 0,
            px: 1,
            flex: { xs: "0 1 auto", lg: 1 },
            flexShrink: 0,
            px: 1,
          }}
        >
          <Typography
            fontSize={"16px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
          >
            Assigned By
          </Typography>
          <Div sx={{ display: "flex", gap: 1 }}>
            {/* <AccountBalanceIcon color="secondary" /> */}
            <Typography fontSize={"14px"}>{item.AssignedBy}</Typography>
          </Div>
        </Div>

        <Div
          sx={{
            display: {
              xs: "none",
              lg: "flex",
            },
            alignItems: "center",
            width: "10%",
            gap: 2,
          }}
        >
          <Chip
            label={`${item.taskStatus}`}
            color="chipColor"
            size="medium"
            sx={{ fontSize: 14, fontWeight: "700" }}
          />
          {/* <EmailOutlinedIcon fontSize="medium" color="secondary" />
          <LocalPhoneOutlinedIcon fontSize="medium" color="secondary" /> */}
        </Div>
        <Div>
          <ActionsMenu />
        </Div>
      </AccordionSummary>

      {/* down arrow content */}
      <Collapse
        in={expanded}
        timeout="auto"
        sx={{
          borderTop: 1,
          borderColor: "divider",
          p: (theme) => theme.spacing(2, 2, 2, 2),
        }}
        unmountOnExit
      >
        <Typography variant={"h5"}>{item.name}</Typography>
        <Div
          sx={{
            display: { xs: "flex", lg: "none" },
            minWidth: 0,
            flexDirection: "column",
          }}
        >
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              alignItems: "center",
              justifyContent: "space-between",
              mt: 1,
              mb: 2,
            }}
          >
            <Div>
              <Chip
                label={`${item.taskStatus}`}
                color="chipColor"
                size="medium"
                sx={{ fontSize: 14, fontWeight: "700" }}
              />
              <Typography variant={"body1"}></Typography>
            </Div>
            <Div sx={{ flex: { xs: "0 1 auto", lg: 1 }, flexShrink: 0, px: 1 }}>
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
                sx={{
                  display: { xs: "block", lg: "none" },
                }}
              >
                {/* Status */}
              </Typography>
              <Chip
                size={"medium"}
                label={`Priority: ${item.priority}`}
                color="warning"
                sx={{
                  display: { xs: "flex", lg: "none" },
                }}
              />
            </Div>
          </Div>
          <Div sx={{ mb: 3, maxWidth: 280 }}>
            <Div
              sx={{
                display: { xs: "flex", lg: "none" },
                gap: 2,
              }}
            >
              {/* <EmailOutlinedIcon fontSize="medium" color="secondary" />
              <LocalPhoneOutlinedIcon fontSize="medium" color="secondary" /> */}
            </Div>
          </Div>
        </Div>

        <Typography variant={"body1"} color={"text.secondary"}>
          {item.taskName}
        </Typography>
      </Collapse>
    </Paper>
  );
};
/* Todo item prop define */
export default ProjectItem;
