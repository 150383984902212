import React from "react";

import { CasePointData } from "./CardData";
import Div from "@jumbo/shared/Div";
import PropertyItem from "./PropertyItem";

const CasePointList = ({ handleScreen, openSnackBar, setOpenSnackBar }) => {
  return (
    <React.Fragment>
      {CasePointData.map((team, index) => (
        // <DocumentCard Key={team.id} item={team} />
        <>
          <Div>
            <PropertyItem
              Key={team.id}
              item={team}
              handleScreen={handleScreen}
              openSnackBar={openSnackBar}
              setOpenSnackBar={setOpenSnackBar}
            />
          </Div>
        </>
      ))}
    </React.Fragment>
  );
};

export default CasePointList;
