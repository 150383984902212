import Div from "@jumbo/shared/Div";
import React from "react";
import UpperSelectBar from "../components/UpperSelectBar";
import { Breadcrumbs, Card, CardContent, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { Diversity3Outlined } from "@mui/icons-material";

const AddLawyerLayout = () => {
  return (
    <Div sx={{ height: "100%", width: "100%" }}>
      <Div sx={{ mb: 2, mt: -3 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} fontSize="inherit" />
            Home
          </Typography>

          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <Diversity3Outlined sx={{ fontSize: 20 }} />
            &nbsp; Teams
          </Typography>
        </Breadcrumbs>
      </Div>

      <Card
        sx={{
          diplay: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <CardContent
          sx={{
            "& .css-17pmaar-MuiCardContent-root": {
              padding: 0,
            },
          }}
        >
          <Div
            sx={{
              width: "100%",
              position: "relative",
              height: "fit-content",
              mt: -2,
              mb: 8,
            }}
          >
            <Div
              sx={{
                position: "absolute",
                mt: 1,
                mb: 1,
                borderBottom: "1px solid #D3D3D3 ",
                width: "100%",
                pt: 0,
                pb: 2,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Breadcrumbs aria-label="breadcrumb">
                <Typography variant="h5" color="text.primary">
                  Teams
                </Typography>
              </Breadcrumbs>

              <Typography
                variant="h6"
                color="text.primary"
                sx={{ cursor: "pointer" }}
              >
                {/* Reset */}
              </Typography>
            </Div>
          </Div>
          <Div
            sx={{
              pl: { sm: "0", lg: "11%" },
              pr: { sm: "0", lg: "11%" },
              m: "auto",
            }}
          >
            <UpperSelectBar />
          </Div>
        </CardContent>
      </Card>
    </Div>
  );
};

export default AddLawyerLayout;
