import React, { useEffect, useState } from "react";

import { Alert, Button, Snackbar, Typography } from "@mui/material";

import Div from "@jumbo/shared/Div";
import EachClient from "./EachClient";
import { LoadingButton } from "@mui/lab";
import ModalScreen from "./ModalScreen";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";

function Witness({ handleBackButton, handleNextButton, setAddCase }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const [error, setError] = useState(true);

  const [count, setCount] = useState(1);
  const [open, setOpen] = useState(false);
  const AddClient = () => {
    setCount((state) => state + 1);
  };

  const handleClose = () => setOpen(false);

  const [openSnackBar, setOpenSnackBar] = useState(false);

  const handleArchieve = () => {
    if (error === false) {
      setOpen(false);
      setOpenSnackBar(true);
    } else setOpenSnackBar(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        paddingBottom: "30px",
      }}
    >
      {openSnackBar && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnackBar}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          sx={{ zIndex: 1000, mt: 10 }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%", pr: 3, pl: 3, borderRadius: "10px" }}
          >
            <Typography variant="h6">Case Added Successfully!</Typography>
            <Div sx={{ display: "flex", justifyContent: "center" }}>
              <LoadingButton
                style={{
                  margin: "0",
                  marginTop: "10px",
                  marginRight: "10px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
                sx={{ backgroundColor: "secondary.main" }}
                variant="contained"
                onClick={() => setAddCase(false)}
              >
                See All Cases
              </LoadingButton>
            </Div>
          </Alert>
        </Snackbar>
      )}
      {/* <CaseContainer> */}

      <Div
        sx={{
          marginLeft: { xs: "0%", md: "18%" },
          marginRight: { xs: "0%", md: "18%" },

          height: "100%",
        }}
      >
        {/* <ToastContainer toastStyle={{ backgroundColor: "#EABF4E" }} /> */}
        {Array(count)
          .fill(count)
          .map((x, idx) => (
            <EachClient
              count={idx}
              clientNo={count}
              setClientNo={setCount}
              name={"Witness"}
              setError={setError}
            />
          ))}
        <Div
          sx={{
            display: "flex",
            pr: 2,
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={AddClient}
            sx={{ backgroundColor: "secondary.main" }}
            style={{
              margin: "0",
              // marginTop: "-10px",
              mr: 2,
            }}
            variant="contained"
          >
            Add New Client
          </Button>
        </Div>
        <Div
          sx={{
            width: "100%",
            pl: 2,
            pr: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 5,
            // backgroundColor: "green",
          }}
        >
          <LoadingButton
            onClick={handleBackButton}
            //   disabled={values !== 0 ? false : true}
            variant="contained"
            sx={{ width: 100, padding: 1 }}
          >
            Back
          </LoadingButton>
          <LoadingButton
            color="secondary"
            style={{
              backgroundColor: "black",
            }}
            sx={{ width: 100, padding: 1 }}
            variant="contained"
            onClick={handleArchieve}
          >
            Continue
          </LoadingButton>
        </Div>
      </Div>
    </div>
  );
}

export default Witness;
