import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import React from "react";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";

function SocialButtons() {
  return (
    <div>
      <Stack
        direction={{ xs: "row", md: "row" }}
        alignItems="center"
        justifyContent="center"
        spacing={1.5}
      >
        <LoadingButton
          fullWidth={false}
          type="submit"
          variant="contained"
          sx={{ width: 150, padding: 1 }}
          color="error"
          onClick={() => alert("Sign Up with google")}
          startIcon={<GoogleIcon />}
        >
          Google
        </LoadingButton>
        <LoadingButton
          fullWidth={false}
          type="submit"
          variant="contained"
          sx={{ width: 150, padding: 1 }}
          color="info"
          onClick={() => alert("Sign Up with facebook")}
          startIcon={<FacebookIcon />}
        >
          facebook
        </LoadingButton>
      </Stack>
    </div>
  );
}

export default SocialButtons;
