import React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Avatar from "@mui/material/Avatar";
import {
  Accordion,
  AccordionDetails,
  alpha,
  Card,
  Collapse,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import Div from "@jumbo/shared/Div";
import { projects } from "./data";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ProjectItem = ({ item }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    if (projects.find((project) => project.id === project.id))
      setExpanded(!expanded);
  };
  return (
    <Paper
      elevation={0}
      variant="outlined"
      square
      sx={{
        mt: 1,
        "&:hover": {
          boxShadow: `0 3px 10px 0 ${alpha("#000", 0.2)}`,
          transform: "translateY(-4px)",
          borderBottomColor: "transparent",
        },
      }}
    >
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          px: 1,
          flexDirection: "row-reverse",

          "& .MuiAccordionSummary-content": {
            alignItems: "center",

            "&.Mui-expanded": {
              margin: "12px 0",
            },
          },
          ".MuiAccordionSummary-expandIconWrapper": {
            borderRadius: 1,
            border: 1,
            color: "text.secondary",
            borderColor: "divider",
            transform: "none",
            height: 28,
            width: 28,
            alignItems: "center",
            justifyContent: "center",
            mr: 1,

            "&.Mui-expanded": {
              transform: "none",
              color: "primary.main",
              borderColor: "primary.main",
            },

            "& svg": {
              fontSize: "1.25rem",
            },
          },
        }}
      >
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          {expanded ? <RemoveIcon /> : <AddIcon />}
        </ExpandMore>

        <Div sx={{ flexShrink: 0, px: 1 }}>
          <Avatar
            sx={{ width: 52, height: 52 }}
            alt={item.name}
            src={item.logo}
            variant="rounded"
          />
        </Div>
        <Div
          sx={{
            width: { xs: "auto", lg: "26%" },
            flexShrink: 0,
            px: 1,
            flex: { xs: "1", lg: "0 1 auto" },
          }}
        >
          <Typography variant={"h5"} mb={0.5} fontSize={14}>
            {item.name}
          </Typography>
        </Div>
        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "20%",
            flexShrink: 0,
            px: 2,
          }}
        ></Div>
        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "16%",
            flexShrink: 0,
            px: 1,
          }}
        >
          <Typography
            fontSize={"12px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
          >
            Deadline
          </Typography>
          <Typography variant={"body1"}>{item.deadline}</Typography>
        </Div>
        <Div sx={{ flex: { xs: "0 1 auto", lg: 1 }, flexShrink: 0, px: 1 }}>
          <Typography
            fontSize={"12px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
            sx={{
              display: { xs: "none", lg: "block" },
            }}
          >
            Status
          </Typography>
          <Chip
            color={item?.status?.chip_color}
            size={"small"}
            label={item.status.label}
            sx={{
              display: { xs: "none", lg: "inline-block" },
            }}
          />
        </Div>
        <Div
          sx={{
            display: {
              xs: "none",
              lg: "flex",
            },

            alignItems: "center",
            flexDirection: "column",
            gap: 1,
          }}
        >
          {item.client.map((client, index) => (
            <>
              <Avatar key={index} alt={client.name} src={client.profilePic} />
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                {client.name}
              </Typography>
            </>
          ))}
        </Div>
      </AccordionSummary>
      <Collapse
        in={expanded}
        timeout="auto"
        sx={{
          borderTop: 1,
          borderColor: "divider",
          p: (theme) => theme.spacing(2, 2, 2, 2),
        }}
        unmountOnExit
      >
        <Typography variant={"h5"}>Description</Typography>
        <Div
          sx={{
            display: { xs: "flex", lg: "none" },
            minWidth: 0,
            flexDirection: "column",
          }}
        >
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              alignItems: "center",
              justifyContent: "space-between",
              mt: 1,
              mb: 2,
            }}
          >
            <Div>
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Deadline
              </Typography>
              <Typography variant={"body1"}>{item.deadline}</Typography>
            </Div>
            <Div sx={{ flex: { xs: "0 1 auto", lg: 1 }, flexShrink: 0, px: 1 }}>
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
                sx={{
                  display: { xs: "block", lg: "none" },
                }}
              >
                Status
              </Typography>
              <Chip
                color={item?.status?.chip_color}
                size={"small"}
                label={item.status.label}
                sx={{
                  display: { xs: "inline-block", lg: "none" },
                }}
              />
            </Div>
          </Div>
          <Div sx={{ mb: 3, maxWidth: 280 }}>
            <Div
              sx={{
                display: { xs: "flex", lg: "none" },
                flexDirection: "column",
                gap: 1,
              }}
            >
              {item.client.map((client, index) => (
                <>
                  <Avatar
                    key={index}
                    alt={client.name}
                    src={client.profilePic}
                  />
                  <Typography
                    fontSize={"12px"}
                    variant={"h6"}
                    color={"text.secondary"}
                    mb={0.25}
                  >
                    {client.name}
                  </Typography>
                </>
              ))}
            </Div>
          </Div>
        </Div>
        <Typography variant={"body1"} color={"text.secondary"}>
          {item.description}
        </Typography>
      </Collapse>
    </Paper>
  );
};
/* Todo item prop define */
export default ProjectItem;
